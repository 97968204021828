import _ from 'lodash'
import autoSave from '../autoStore'
import { makeAutoObservable } from 'mobx'
import { WorkPackages } from 'app/services'

class PeriodStore {
    periods: any[] = []

    constructor() {
        makeAutoObservable(this)
        autoSave(this)
    }

    set(periodList: any[]) {
        this.periods = periodList
    }

    get(reload?: boolean) {
        if (reload || this.periods.length == 0) {
            return WorkPackages.BusinessWorkPackageService.PeriodService.get().then((data: any) => {
                let periods = data?.data
                periods = _.orderBy(periods, ['active', 'begin'], ['desc', 'desc'])

                this.periods = periods
                return periods
            })
        }

        return new Promise((resolve, reject) => resolve(this.periods))
    }

    getAllPeriods() {
        return this.periods
    }
}

const period = new PeriodStore()
export { period }
