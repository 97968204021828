import { ApiService, NormalizeResponse, SERVICES } from './BaseService';

export const ACLService = {
    getRoles: () => {
        return NormalizeResponse(ApiService(SERVICES.CONNECTOR_BACKEND).get('acl/roles'));
    },
    upsertRole: (role: any) => {
        return ApiService(SERVICES.CONNECTOR_BACKEND).post('acl/roles/upsert', role);
    },
    getRoleById: (id: number) => {
        return NormalizeResponse(ApiService(SERVICES.CONNECTOR_BACKEND).get(`acl/roles/${id}`));
    },
    deleteRoleById: (id: number) => {
        return NormalizeResponse(ApiService(SERVICES.CONNECTOR_BACKEND).delete(`acl/roles/${id}`));
    },
    getPermissions: () => {
        return NormalizeResponse(ApiService(SERVICES.CONNECTOR_BACKEND).get('acl/permissions'));
    },
    getPermissionsByRoleSlug: (slug: string) => {
        return NormalizeResponse(ApiService(SERVICES.CONNECTOR_BACKEND).get(`acl/roles/${slug}/permissions`));
    }
}