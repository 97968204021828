import _ from 'lodash';
import autoSave from '../autoStore';
import { makeAutoObservable, toJS } from "mobx"
import { WorkPackages } from 'app/services';
import { RootStore } from '../useStore';

class SourceStore {
    rootStore: any = null;
    sources: any[] = []

    constructor() {
        makeAutoObservable(this)
        autoSave(this);
    }

    set(sourceList: any[]) {
        this.sources = sourceList;
    }

    get(filters?: any, reload?: boolean) {
        if(reload || this.sources.length == 0) {
            return WorkPackages.BusinessWorkPackageService.AcopioService.get(filters).then((data: any) => {
                let sources = data?.data;
                this.sources = _.map(sources, this.formatSource);

                return sources;
            });
        }

        return new Promise((resolve, reject) => resolve(this.sources));
    }

    getById(id: number) {
        return this.get().then((sources: any[]) => {
            return toJS(sources).find((i: any) => i.id == id);
        });
    }

    getByIds(ids: number[]) {
        ids = _.map(ids, (i: any) => parseInt(i));
        return this.get({}, true).then((sources: any[]) => {
            return toJS(sources).filter((i: any) => ids.indexOf(i.id) > -1);
        });
    }

    getByIdentityCodes(identityCodes: any[]) {
        return this.get({}, true).then((sources: any[]) => {

            // Parse sources
            const sourceList = toJS(sources).filter((i: any) => identityCodes.indexOf(i.identity_code) > -1);
            return _.map(sourceList, (i: any) => {
                return this.formatSource(i);
            })
        });
    }

    getMySource() {
        const user = RootStore.user.getUser()
        if(user) {
            const source = _.find(this.sources, (i: any) => i.id === user.wp_data.source_id);
            return toJS(source);
        }

        return null;
    }

    formatSource = (i: any) => {
        return {
            id: i.source_agent_id,
            full_name: i.source_agent_desc,
            identity_code: i.source_identity_code,
            party_id: i.source_agent_party_fk,
            type_id: i.source_agent_type_fk,
            groups: i.groups,
            managerOf: i.managerOf
        }
    }
}

const source = new SourceStore();

export {
    source
};