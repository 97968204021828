import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authCrud";
import cookie from 'js-cookie';
import _ from 'lodash';
import { SERVICES } from 'app/services/BaseService';

export const actionTypes = {
    Login: "[Login] Action",
    Logout: "[Logout] Action",
    Register: "[Register] Action",
    UserRequested: "[Request User] Action",
    UserLoaded: "[Load User] Auth API",
    SetUser: "[Set User] Action",
};

const initialAuthState = {
    user: undefined,
    authToken: undefined,
};

export const reducer = persistReducer(
    { storage, key: "v726-demo1-auth", whitelist: ["authToken"] },
    (state = initialAuthState, action) => {
        switch (action.type) {
            case actionTypes.Login: {
                const { authToken } = action.payload;

                return { authToken, user: undefined };
            }

            case actionTypes.Register: {
                const { authToken } = action.payload;

                return { authToken, user: undefined };
            }

            case actionTypes.Logout: {
                // TODO: Change this code. Actions in reducer aren't allowed.

                const key = `access_token_${SERVICES.CONNECTOR_BACKEND.ID}`;
                cookie.remove(key);
                cookie.remove(`auth_provider`);
                return initialAuthState;
            }

            case actionTypes.UserLoaded: {
                const { user } = action.payload;
                return { ...state, user };
            }

            case actionTypes.SetUser: {
                const { user } = action.payload;
                return { ...state, user };
            }

            default:
                return state;
        }
    }
);

export const actions = {
    login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
    register: (authToken) => ({
        type: actionTypes.Register,
        payload: { authToken },
    }),
    logout: () => ({ type: actionTypes.Logout }),
    requestUser: (user) => ({
        type: actionTypes.UserRequested,
        payload: { user },
    }),
    fulfillUser: (user) => {
        // Put additional methods on the user
        if(user) {
            user.hasPermission = (permission) => {
                if(user && user.permissions_list) {
                    return user.permissions_list.indexOf(permission) > -1;
                }

                return null;
            };
            user.hasRole = (slug) => {
                if(user.roles_list && user.roles_list.length > 0) {
                    return _.some(user.roles_list, (i) => i && i.slug.toLowerCase() == slug.toLowerCase());
                }

                return user.role && user.role.slug && slug && user.role.slug.toLowerCase() == slug.toLowerCase();
            };
            user.isFromOrganization = (organizationSlug) => {
                if(user && user.organizations) {
                    return _.some(user.organizations, (i) => i.slug == organizationSlug);
                }

                return false;
            };
            user.getPartyId = () => {
                if(user && user.organizations) {
                    return _.first(user.organizations).party_id;
                }

                return false;
            };
        }

        return (
            {
                type: actionTypes.UserLoaded,
                payload: { user }
            }
        );
    },
    setUser: (user) => {
        return {
            type: actionTypes.SetUser,
            payload: { user }
        }
    },
};

export function* saga() {
    yield takeLatest(actionTypes.Login, function* loginSaga() {
        yield put(actions.requestUser());
    });

    yield takeLatest(actionTypes.Register, function* registerSaga() {
        yield put(actions.requestUser());
    });

    yield takeLatest(actionTypes.UserRequested, function* userRequested() {
        const { data: user } = yield getUserByToken();
        user.user.wp_data = user.wp_data;
        yield put(actions.fulfillUser(user.user));
    });
}
