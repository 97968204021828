import React from "react";

// Import all the stores
import { period } from './stores/period.store';
import { product } from './stores/product.store';
import { source } from './stores/source.store';
import { ui } from './stores/ui.store';
import { user } from './stores/user.store';
import { configuration } from './stores/configuration.store';
import { forecast } from './stores/forecast.store';

class RootStores {
    public period;
    public product;
    public source;
    public ui;
    public user;
    public configuration;
    public forecast;

    constructor() {
        this.period = period;
        this.product = product;
        this.source = source;
        this.ui = ui;
        this.user = user;
        this.configuration = configuration;
        this.forecast = forecast;
    }
}

export const RootStore = new RootStores();
export const StoresContext = React.createContext(RootStore);

// this will be the function available for the app to connect to the stores
export const useStores = () => React.useContext(StoresContext);