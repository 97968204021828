import _ from 'lodash';

export const NUMBERS_FORMATS = {
    LOOKUP: 'lookup',
    DIGITS: 'digits'
};

export function isNumberNotZero(num: number) {
    return _.isNumber(num) && num != 0;
}

export function formatNumberLookup(num: number, format?: any, options?: any) {
    if(!format) format = 'digits';
    if(!options) {
        options = {
            digits: 2
        };
    }

    // If they are digits then just use the en-US format for formatting
    if(format == NUMBERS_FORMATS.DIGITS) {
        var commas = num.toLocaleString("en-US");
        return commas;
    }

    const lookup = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'k' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'G' },
        { value: 1e12, symbol: 'T' },
        { value: 1e15, symbol: 'P' },
        { value: 1e18, symbol: 'E' },
    ]
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
    var item = lookup
        .slice()
        .reverse()
        .find(function(item) {
            return Math.abs(num) >= item.value
        })

    return item
        ? (num / item.value).toFixed(options.digits).replace(rx, '$1') + item.symbol
        : '0'
}

export function zeroPad(num: any, count: number) {
    var str = '' + num;
    while (str.length < count) {
        str = 0 + str;
    }
    return str;
}