/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'

export function CreateTicketPage() {
    return (
        <div style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            /* boxShadow: '5px 5px 56px 0px rgba(0,0,0,0.25)', */
            borderRadius: 10,
            overflow: 'hidden'
        }}>
            <iframe
                src="https://forms.monday.com/forms/embed/31c730411ef9f0e9f8487d2422b309ed?r=use1"
                width="100%"
                height="100%"
                style={{
                    border: 0
                }}
            />
        </div>
    )
}