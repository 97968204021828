import axios from 'axios';
import axiosRetry from 'axios-retry';
// @ts-ignore
import cookie from 'js-cookie';

/** This function configure the Axios library **/

export var SERVICES = {
    FRONT_URL: {
        LOCAL: process.env.REACT_APP_BASE_URL,
        PRODUCTION: process.env.REACT_APP_BASE_URL
    },
    CONNECTOR_BACKEND: {
        ID: `bloom_backend`,
        LOCAL: process.env.REACT_APP_BASE_API_CORE_URL,
        PRODUCTION: process.env.REACT_APP_BASE_API_CORE_URL
    },
    WORK_PACKAGE_BUSINESS: {
        ID: `bloom_connector_data`,
        LOCAL: process.env.REACT_APP_CONNECTOR_DATA,
        PRODUCTION: process.env.REACT_APP_CONNECTOR_DATA,
    }
};

// This is a list for cancel tokens
// The key is the URL being called
// The object is the cancel token to cancel previous requests in axios
var CANCEL_TOKENS: any = {};

export function ApiService(service: any, timeout = 360 * 1000, headers: any = null, options: any = {
    retries: 3
}) {

    if(!headers) {
        headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
    }

    // Instance the webservice caller
    var api = axios.create({
        baseURL: getBaseUrl(service),
        timeout: timeout,
        headers: headers
    });

    // Set the AUTH token for any request
    api.interceptors.request.use(function (config) {
        const token = cookie.get(`access_token_${service.ID}`);
        if(token && token != '') {
            config.headers.Authorization =  token ? `Bearer ${token}` : '';
        }

        /* // @ts-ignore
        if(CANCEL_TOKENS[config.url]) {
            // @ts-ignore
            CANCEL_TOKENS[config.url].cancel();
        } else {
            // @ts-ignore
            config.cancelToken = addCancelToken(config.url).token;
        } */

        return config;
    });

    // @ts-ignore
    api.interceptors.response.use((response: any) => {
        removeCancelToken(response.config.url);
        return response;
    });

    axiosRetry(api, {
        retries: options.retries,
        retryDelay: (retryCount) => {
            return retryCount * 2500;
        }
    });

    return api;
}

export function NormalizeResponse(promise: Promise<any>) {
    return new Promise((resolve, reject) => {
        promise.then((data) => {
            if(data.status == 200) {
                resolve(data.data);
            } else {
                resolve(null)
            }
        }).catch((err) => {
            reject(err);
        });
    });
}

export function parseJWT(service: any) {
    const token = getAccessToken(service);
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export function parseJWTFromToken(token: any) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export function getBaseUrl(service: any) {
    const url = localStorage.getItem(`ENDPOINT_URL_${service.ID}`);
    if(url != null)
        return url;

    return process.env.REACT_APP_ENV == 'production' ? service.PRODUCTION : service.LOCAL;
}

export function setBaseURL(url: string, service: any) {
    if(true || process.env.REACT_APP_ENV == 'production') {
        localStorage.setItem(`ENDPOINT_URL_${service.ID}`, url);
        return true;
    }

    return false;
}

/* Access token related functions */
export function removeAccessToken(service: any) {
    setAccessToken(service, null);
}

export function setAccessToken(service: any, token: any) {
    const key = `access_token_${service.ID}`;
    // Reload if the access token is different
    if(token == null) {
        cookie.remove(key);
    } else {
        const currentToken = cookie.get(key);
        cookie.set(key, token)

        // If we change the access token, then refresh
        if(currentToken != token) {
            const newPayload: any = parseJWTFromToken(token);
            const oldPayload: any = parseJWT(service);
            if(newPayload && oldPayload && newPayload.user_id != oldPayload.user_id) {
                window.location.reload();
            }
        }
    }
}

export function getAccessToken(service: any) {
    const key = `access_token_${service.ID}`;
    return cookie.get(key)
}

export function addCancelToken(url: string) {
    CANCEL_TOKENS[url] = axios.CancelToken.source();
    return CANCEL_TOKENS[url];
}

export function getCancelToken(url: string) {
    return CANCEL_TOKENS[url];
}

export function removeCancelToken(url: string) {
    delete CANCEL_TOKENS[url];
}

export function encodeQueryData(parameters: any) {
    let ret = [];
    for (let d in parameters) {
        if(parameters[d] != null) {
            if(Array.isArray(parameters[d])) {
                for(var idx in parameters[d]) {
                    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(parameters[d][idx]));
                }
            } else {
                ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(parameters[d]));
            }
        }
    }
    return ret.join('&');
}