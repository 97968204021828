export const getFileInfoFromURL = (url: string) => {
    const fileInfo = {
        format: '',
        placeholder: ''
    };

    url = url.toLowerCase();
    if(url.indexOf('pdf') > -1) {
        fileInfo.format = 'PDF';
    } else if(url.indexOf('jpg') > -1 || url.indexOf('jpeg') > -1 || url.indexOf('png') > -1) {
        fileInfo.format = 'IMAGE';
    }

    return fileInfo;
}