/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'

export function BreadCrumbs({ items, user }) {
    if (!items || !items.length) {
        return ''
    }

    return (
        <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2">
            <li className="breadcrumb-item">
                {
                    user.role.slug === "administrator" ?
                    (
                        <i className="flaticon2-shelter text-muted icon-1x" />
                    ):
                    (
                        <Link to="/dashboard">
                            <i className="flaticon2-shelter text-muted icon-1x" />
                        </Link>
                    )
                    
                }
            </li>
            {items.map((item, index) => {
                const isLast = (index === items.length - 1);
                if(isLast) {
                    return (
                        <li key={`bc${index}`} className="breadcrumb-item">
                            <p
                                className="text-muted"
                                style={{ margin: 0 }}>
                                {item.title}
                            </p>
                        </li>
                    );
                }

                return (
                    <li key={`bc${index}`} className="breadcrumb-item">
                        <Link
                            className="text-muted"
                            to={{ pathname: item.pathname }}>
                            {item.title}
                        </Link>
                    </li>
                );
            })}
        </ul>
    )
}
