import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import _ from 'lodash';
// LayoutContext
// Import Layout components
import { Header } from "./header/Header";
import { HeaderMobile } from "./header-mobile/HeaderMobile";
import { Aside } from "./aside/Aside";
/* import { Footer } from "./footer/Footer"; */
import { LayoutInit } from "./LayoutInit";
import { ScrollTop } from "./extras/ScrollTop";

export function Layout({ children }) {
    const { user } = useSelector((state) => state.auth, shallowEqual);

    let organizationSlug = '';
    if (user && user.organizations) {
        organizationSlug = _.first(user.organizations).slug;
    }

    return (
        <>
            {/*begin::Main*/}
            <HeaderMobile />
            <div className={`d-flex flex-column flex-root organization_${organizationSlug}`}>
                
                <div className="d-flex flex-row flex-column-fluid page">
                    <Aside />
                    
                    <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                        <Header />
                        <div
                            id="kt_content"
                            className={`content d-flex flex-column flex-column-fluid`}>

                            <div className="d-flex flex-column-fluid">
                                <div className={'container-fluid'}>
                                    {children}
                                </div>
                            </div>

                        </div>
                        
                        {/* <Footer /> */}
                    </div>
                </div>
            </div>
            {/* <QuickUser />
            <QuickPanel /> */}
            <ScrollTop />
            {/* <StickyToolbar /> */}
            <LayoutInit />
        </>
    );
}
