import { ApiService, NormalizeResponse, SERVICES } from './BaseService';

export const RedisService = {
    set: (key: string, value: any) => {
        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).post('redis/post', {
            key,
            value
        }));
    },
    get: (key: string) => {
        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`redis/get?key=${key}`));
    },
    delete: (key: string) => {
        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).delete(`redis/${key}`));
    }
}