import { ApiService, encodeQueryData, NormalizeResponse, SERVICES } from '../../BaseService';

export const PeriodService = {
    get: (filters?: any) => {
        let url = `periods`;
        if(filters) url += `?${encodeQueryData(filters)}`;

        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(url));
    },
    getById: (id: number) => {
        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`periods/${id}`));
    }
};
