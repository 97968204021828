import autoSave from '../autoStore';
import { makeAutoObservable } from "mobx"

class UserStore {
    user: any;
    flags: any = {
        bonificationsAvailable: false
    };
    actingAsRegional: boolean = false;
    managerOf: any[] = [];

    constructor() {
        makeAutoObservable(this)
        autoSave(this);
    }

    isBonificationsAvailable(bonificationsEnabled: boolean) {
        this.flags = {
            ...this.flags,
            bonificationsAvailable: bonificationsEnabled
        };
    }

    setUser(user: any) {
        this.user = user;
    }

    getUser() {
        return this.user;
    }

    setActingAsRegional(acting: boolean) {
        this.actingAsRegional = acting;
    }

    isActingAsRegional(): boolean {
        return this.managerOf && this.managerOf.length > 0 && this.actingAsRegional;
    }

    canActAsRegional(): boolean {
        return this.managerOf?.length > 0;
    }

    setManagerOf(managerOf: any[]) {
        this.managerOf = managerOf;
    }
}

const user = new UserStore();

export {
    user
};