import React, { lazy, Suspense, useEffect } from 'react'
import { useHistory } from 'react-router'
import { Redirect, Switch } from 'react-router-dom'
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout'
import { GenericDashboardPage } from './pages/PowerBI/GenericDashboardPage';
import { SalesDashboardPage } from './pages/PowerBI/SalesDashboardPage';
import { ImportsDashboardPage } from './pages/PowerBI/ImportsDashboardPage';
import { KPIsDashboardPage } from './pages/PowerBI/KPIsDashboardPage'
import { CreateTicketPage } from './pages/Tickets/create'
import { UserService } from 'app/services'

import { AdminRoute } from './routes/index'
import { useAnalytics } from 'app/hooks'

// Private UPL
import SalesUPL from './pages/Unauthorized/SalesUPL'
import { useSelector } from 'react-redux'

/* RTV and Sales */

const WorkPackageForecastVisualization = lazy(() => import('app/pages/WorkPackages/Forecast/visualization'));
const WorkPackageForecastSubmit = lazy(() => import('app/pages/WorkPackages/Forecast/submit'));
const WorkPackageForecastsValidate = lazy(() => import('app/pages/WorkPackages/Forecast/validate'));
const WorkPackageForecastsConsolidation = lazy(() => import('app/pages/WorkPackages/Forecast/consolidation'));
const WorkPackageForecastsVerificationRegion = lazy(() => import('app/pages/WorkPackages/Forecast/verification_region'));
const WorkPackageForecastsVerification = lazy(() => import('app/pages/WorkPackages/Forecast/verification'));
const WorkPackageForecastsList = lazy(() => import('app/pages/WorkPackages/Forecast/list'));
const WorkPackageForecastConfiguration = lazy(() => import('app/pages/WorkPackages/Forecast/configuration'));

// Admin
const AdminSourcesList = lazy(() => import('app/pages/Admin/Sources/list'))
const AdminPricingsList = lazy(() => import('app/pages/Admin/Pricings/list'))
const AdminUsersList = lazy(() => import('app/pages/Admin/Users/list'))
const AdminUsersEdit = lazy(() => import('app/pages/Admin/Users/edit'))
const AdminRolesList = lazy(() => import('app/pages/Admin/Roles/list'))
const AdminRolesEdit = lazy(() => import('app/pages/Admin/Roles/edit'))
const AdminOrganizationsList = lazy(() => import('app/pages/Admin/Organizations/list'))
const AdminOrganizationsEdit = lazy(() => import('app/pages/Admin/Organizations/edit'))
const AdminDataUploadHome = lazy(() => import('app/pages/Admin/DataUpload/index'))
const AdminDataUploadLogs = lazy(() => import('app/pages/Admin/DataUpload/logs'))
const AdminModulesPage = lazy(() => import('app/pages/Admin/ModulesPage'))

export default function BasePage() {
    const { user } = useSelector(state => state.auth)
    const history = useHistory()
    const analytics = useAnalytics()

    useEffect(() => {
        if (analytics && history) {
            analytics.trackPageView(history.location.pathname)
            history.listen(location => {
                analytics.trackPageView(location.pathname)
            })
        }
    }, [analytics, history])

    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    const isAdmin = () => {
        return user?.hasRole(UserService.roles().ADMINISTRATOR)
    }

    const isSales = () => {
        return user?.hasRole(UserService.roles().SALES);
    }

    const isRTV = () => {
        return user?.hasRole(UserService.roles().RTV);
    }

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {/* Private UPL Zone */}
                {user && user.hasRole(UserService.roles().APP_PRECIOS) && <ContentRoute path="/dashboard/app_precios" component={SalesUPL} />}

                <ContentRoute path="/reports/sales" component={SalesDashboardPage} />
                <ContentRoute path="/reports/forecast" component={KPIsDashboardPage} />
                <ContentRoute path="/reports/kpis" component={KPIsDashboardPage} />
                <ContentRoute path="/reports/supplying" component={ImportsDashboardPage} />
                <ContentRoute path="/reports/:id" component={GenericDashboardPage} />
                <ContentRoute path="/tickets/create" component={CreateTicketPage} />

                {/* Work packages */}
                <ContentRoute path="/forecasts" exact component={WorkPackageForecastsList} render={null} children={null} />,
                <ContentRoute path="/forecasts/visualization" exact component={WorkPackageForecastVisualization} render={null} children={null} />,
                <ContentRoute path="/forecasts/configuration" exact component={WorkPackageForecastConfiguration} render={null} children={null} />,
                <ContentRoute path="/forecasts/validate" exact component={WorkPackageForecastsValidate} render={null} children={null} />,
                <ContentRoute path="/forecasts/verification_region" exact component={WorkPackageForecastsVerificationRegion} render={null} children={null} />,
                <ContentRoute path="/forecasts/verification" exact component={WorkPackageForecastsVerification} render={null} children={null} />,
                <ContentRoute path="/forecasts/consolidation" exact component={WorkPackageForecastsConsolidation} render={null} children={null} />,
                <ContentRoute path="/forecasts/:id" exact component={WorkPackageForecastSubmit} render={null} children={null} />,

                {isAdmin() ? (
                    <>
                        <AdminRoute path="/admin" exact component={AdminUsersList} />
                        <AdminRoute path="/admin/data/upload" exact component={AdminDataUploadHome} />
                        <AdminRoute path="/admin/data/logs" exact component={AdminDataUploadLogs} />
                        <AdminRoute path="/admin/users" exact component={AdminUsersList} />
                        <AdminRoute path="/admin/sources" exact component={AdminSourcesList} />
                        <AdminRoute path="/admin/pricings" exact component={AdminPricingsList} />
                        <AdminRoute path="/admin/users/:id" exact component={AdminUsersEdit} />
                        <AdminRoute path="/admin/roles" exact component={AdminRolesList} />
                        <AdminRoute path="/admin/roles/:id" exact component={AdminRolesEdit} />
                        <AdminRoute path="/admin/organizations" exact component={AdminOrganizationsList} />
                        <AdminRoute path="/admin/organizations/:id" exact component={AdminOrganizationsEdit} />
                        <ContentRoute path="/admin/modules" exact component={AdminModulesPage} />
                    </>
                ) : null}

                { /* Redirects for every empty / unmatched path */}
                {isSales() && <Redirect to="/forecasts" />}
                {isRTV() && <Redirect to="/forecasts" />}
                {!isSales() && !isAdmin() && !isRTV() && <Redirect to="/forecasts" />}
            </Switch>

            {isAdmin() && !isSales() && !isRTV() && <Redirect to="/admin" />}
        </Suspense>
    )
}
