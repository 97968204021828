import { ApiService, encodeQueryData, NormalizeResponse, SERVICES } from '../../BaseService';

export const ClientService = {
    getSummaryByAcopioIds: (acopioIds: number[], filters?: any) => {
        let url = `clients/acopio/summary?`;
        for(var idx in acopioIds) {
            url += `acopio_ids=${acopioIds[idx]}&`;
        }
        url += `${encodeQueryData(filters)}`;

        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(url));
    }
};
