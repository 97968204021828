import React, { useEffect, useState } from 'react'

import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

// @ts-ignore
import OverlayLoading from 'app/components/OverlayLoading'
import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client'
import { toast } from 'react-hot-toast'
import Tooltip from 'app/components/Tooltip'
import { getBaseUrl, SERVICES } from 'app/services/BaseService'
import { useAnalytics } from 'app/hooks'
import { ReportService } from 'app/services'
import { useParams } from 'react-router-dom'

export function GenericDashboardPage() {
    const params = useParams()
    const analytics = useAnalytics()
    const [loading, setLoading] = useState<boolean>(false)
    const [embedData, setEmbedData] = useState<any>(null)

    const [report, setReport] = useState<any>(null)

    useEffect(() => {
        // Parse the ID
        const paramId = (params as any).id || 0
        if (paramId > 0) {
            setLoading(true)
            ReportService.getPowerBIReportById(paramId)
                .then(response => {
                    if (response.status == 200 && response.data && response.data.data) {
                        const data = response.data.data
                        setReport(data)
                    }
                })
                .catch(err => {
                    console.log(`An error occurred..`, err)
                })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        console.log(report)
        if (report?.report_id != null) {
            let baseUrl = getBaseUrl(SERVICES.CONNECTOR_BACKEND)
            if (baseUrl.endsWith('/')) baseUrl = baseUrl.slice(0, -1)
            let url = `${baseUrl}/azure/reports/getEmbedToken?reportId=${report?.report_id}&datasetId=${report?.dataset_id}&workspaceId=${report?.workspace_id}`

            if (report?.identities && report?.identities.length > 0) url += `&identities=${report?.identities}`

            console.log(url)
            fetch(url)
                .then(res => res.json())
                .then(result => {
                    if (result != null) {
                        analytics.trackEvent('Navigation', {
                            type: window.location.pathname,
                        })

                        setEmbedData({
                            accessToken: result.token,
                            embedUrl: result.embedUrl,
                            reportId: result.reportId,
                        })

                        setLoading(false)
                    }
                })
                .catch((err: any) => {
                    toast.error('Error al cargar el reporte. Inténtelo nuevamente en unos minutos.')
                })
                .finally(() => {
                    setLoading(false)
                })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [report?.report_id])

    return (
        <div style={{ position: 'relative', padding: 10 }}>
            <h1 style={{ marginBottom: 20 }}>
                {report?.title}
                <Tooltip
                    title={
                        <>
                            <p className={'m-2 text-center'}>
                                ID de reporte: <b>{report?.report_id}</b>
                            </p>
                            <p className={'m-2 text-center'}>
                                ID de dataset: <b>{report?.dataset_id}</b>
                            </p>
                            <p className={'m-0 text-center'}>
                                ID de workspace: <b>{report?.workspace_id}</b>
                            </p>
                        </>
                    }>
                    <HelpOutlineIcon style={{ marginLeft: 10, fontSize: '0.9em', color: 'rgba(0,0,0,.5)' }} />
                </Tooltip>
            </h1>

            {embedData != null && (
                <PowerBIEmbed
                    embedConfig={{
                        type: 'report', // Supported types: report, dashboard, tile, visual and qna
                        id: embedData.reportId,
                        embedUrl: embedData.embedUrl,
                        accessToken: embedData.accessToken,
                        tokenType: models.TokenType.Embed,
                        settings: {
                            panes: {
                                filters: {
                                    expanded: false,
                                    visible: false,
                                },
                            },
                            background: models.BackgroundType.Transparent,
                        },
                    }}
                    eventHandlers={
                        new Map([
                            ['loaded', (event: any, embeddedReport: any) => {}],
                            [
                                'rendered',
                                (event: any, embeddedReport: any) => {
                                    setLoading(false)
                                },
                            ],
                            [
                                'error',
                                (event: any) => {
                                    /* console.log(event.detail); */
                                },
                            ],
                            [
                                'visualClicked',
                                (event: any) => {
                                    console.log('Visual clicked', event)
                                },
                            ],
                            [
                                'dataSelected',
                                (event: any, embeddedReport: any) => {
                                    console.log('Data selected', event)
                                },
                            ],
                            [
                                'selectionChanged',
                                (event: any) => {
                                    console.log('Selection changed', event)
                                },
                            ],
                        ])
                    }
                    cssClassName={'report-style-class'}
                    getEmbeddedComponent={embeddedReport => {}}
                />
            )}

            <OverlayLoading visible={loading} />
        </div>
    )
}