/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useLayoutEffect, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { BreadCrumbs } from '../subheader/components/BreadCrumbs'
import {
    getBreadcrumbsAndTitle,
    useSubheader,
} from '../../_core/MetronicSubheader'
import { shallowEqual, useSelector } from 'react-redux';
import { UserNotificationsDropdown } from '../extras/dropdowns/UserNotificationsDropdown'
import { QuickUserToggler } from '../extras/QuiclUserToggler'

export function Header() {
    const location = useLocation()
    const subheader = useSubheader()

    const { user } = useSelector((state) => state.auth, shallowEqual);

    useLayoutEffect(() => {
        const aside = getBreadcrumbsAndTitle('kt_aside_menu', location.pathname)
        const header = getBreadcrumbsAndTitle(
            'kt_header_menu',
            location.pathname
        )
        const breadcrumbs =
            aside && aside.breadcrumbs.length > 0
                ? aside.breadcrumbs
                : header.breadcrumbs
        subheader.setBreadcrumbs(breadcrumbs)
        subheader.setTitle(
            aside && aside.title && aside.title.length > 0
                ? aside.title
                : header.title
        )
        // eslint-disable-next-line
    }, [location.pathname])

    // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
    useEffect(() => {}, [subheader])

    return (
        <div className="d-flex flex-column w-100">
            <div
                className={`header`}
                /* className={`header`} */
                id="kt_header"
                style={{ paddingLeft: 25, paddingRight: 25 }}>
                {/*begin::Container*/}
                <div className={`w-100 d-flex align-items-stretch justify-content-between`}>
                    <div
                        id="kt_subheader"
                        style={{ display: 'flex', flex: 1 }}>
                        <div
                            className={`d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}>
                            <div className="d-flex align-items-center flex-wrap mr-1">
                                <div className="d-flex align-items-baseline mr-5">
                                    <h5 className="text-dark font-weight-bold my-2 mr-5">
                                        <>{subheader.title}</>
                                    </h5>
                                </div>

                                <BreadCrumbs user={ user } items={subheader.breadcrumbs} />
                            </div>
                        </div>
                    </div>

                    <div className="topbar">
                        <UserNotificationsDropdown />
                        <QuickUserToggler />
                    </div>
                </div>
            </div>

            {/* <div style={{ width: '100%', margin: '0 auto', paddingLeft: 25, paddingRight: 25 }}>
                <div style={{ width: '100%', background: '#D6D2C6', height: 11, opacity: .3 }}></div>
            </div> */}
        </div>
    );
}