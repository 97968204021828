import React from 'react';
import { Route } from 'react-router-dom';

const AdminRoute = (props: any) => {
    // Check if user is administrator. Otherwise, show screen of invalid permissions
    return (
        <Route
            {...props}
        />
    )
}

const SupplierRoute = (props: any) => {
    // Check if user is administrator. Otherwise, show screen of invalid permissions
    return (
        <Route
            {...props}
        />
    )
}

const DistributorRoute = (props: any) => {
    // Check if user is administrator. Otherwise, show screen of invalid permissions
    return (
        <Route
            {...props}
        />
    )
}

const FarmerRoute = (props: any) => {
    // Check if user is administrator. Otherwise, show screen of invalid permissions
    return (
        <Route
            {...props}
        />
    )
}

export {
    AdminRoute,
    SupplierRoute,
    DistributorRoute,
    FarmerRoute,
}