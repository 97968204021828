/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { AuthInit } from "./modules/Auth";
import { Routes } from "../app/Routes";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import { Toaster } from 'react-hot-toast';

/* Analytics */
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "app/config/AppInsights";

import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

// Contexts & UI components
import 'animate.css';

/* Msal for azure authentication */
const config = {
    auth: {
        clientId: '4218aef7-1d0e-4de2-9695-8317d0ea6f07',
        clientSecret: '--hvY_3Qckf4rZj-w-0MA-Oa9D4J.444H4',
        knownAuthorities: [ '8bc97f9a-ea86-472e-8ab7-19db58c4c8af.b2clogin.com' ],
        // redirectUri: process.env.REACT_APP_ENV === 'production' ? 'http://191.235.91.75:3333/api/v1/auth/login-redirect' : 'http://localhost:3333/api/v1/auth/login-redirect',
        cacheLocation: 'localStorage'
    }
}
const pca = new PublicClientApplication(config);

export default function App({ store, persistor, basename }) {
    return (
        <Provider store={store}>
            <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
                <React.Suspense fallback={<LayoutSplashScreen />}>
                    <AppInsightsContext.Provider value={reactPlugin}>
                        <BrowserRouter basename={basename}>
                            <MsalProvider instance={pca}>
                                <MaterialThemeProvider>
                                    <AuthInit>
                                        <Routes />
                                    </AuthInit>

                                    <Toaster
                                        toastOptions={{
                                            style: {
                                                borderRadius: '10px',
                                                background: '#333',
                                                color: '#fff',
                                                fontWeight: 800,
                                                fontSize: 14,
                                                textAlign: 'center'
                                            },
                                        }}
                                    />
                                </MaterialThemeProvider>
                            </MsalProvider>
                        </BrowserRouter>
                    </AppInsightsContext.Provider>
                </React.Suspense>
            </PersistGate>
        </Provider>
    );
}
