import { ApiService, NormalizeResponse, SERVICES, encodeQueryData } from './BaseService'

export const AdminService = {
    getSourceAgents: ({ include_targets = false }) => {
        return ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`admin/source_agents?include_targets=${include_targets}`)
    },
    getSourceGroups: () => {
        return ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`admin/source_groups`)
    },
    getSourceAgentById: (id: number) => {
        return ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`admin/source_agents?ids=${id}`)
    },
    getTargetAgents: () => {
        return ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get('admin/target_agents')
    },
    updateSourceGroupsBySourceId: (sourceId: any, sourceGroupIds: any) => {
        return ApiService(SERVICES.WORK_PACKAGE_BUSINESS).post(`admin/source_agents/${sourceId}/groups`, { source_group_ids: sourceGroupIds })
    },
    updateSourceGroupsByTargetId: (id: number, source_group_ids?: number[]) => {
        return ApiService(SERVICES.WORK_PACKAGE_BUSINESS, 5 * 1000, null, { retries: 0 }).put(`admin/target_agents/${id}/source_groups`, {
            source_group_ids,
        })
    },
    getTargetAgentsBySourceId: (sourceId: number) => {
        return ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`admin/source_agents/${sourceId}/target_agents`)
    },
    /* Pricing methods */
    getPricings: (filters: any) => {
        return ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`admin/pricings?${encodeQueryData(filters)}`)
    },
    downloadPricingById: (pricingId: any) => {
        return NormalizeResponse(
            ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`admin/pricings/${pricingId}/download`, {
                responseType: 'blob',
            })
        )
    },
    uploadPricingById: (pricingId: any, file: File) => {
        var FormData = require('form-data')
        var data = new FormData()
        data.append('file', file)

        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).post(`admin/pricings/${pricingId}/update`, data))
    },
}
