/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link, Switch, Redirect } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/_helpers'
import { ContentRoute } from '../../../../_metronic/layout'
import Login from './Login'
import '../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss'
import LoginCredentials from './LoginCredentials'

export function AuthPage() {
    return (
        <>
            <div className="d-flex flex-column flex-root">
                <div
                    className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
                    id="kt_login">
                    <div
                        className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat"
                        style={{
                            /* backgroundImage: `url(${toAbsoluteUrl(
                                '/media/bg/bg-4.jpg'
                            )})`, */
                            background: 'white',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '5%',
                            boxShadow: 'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset'
                        }}>
                        <div className="d-flex flex-row-fluid flex-column justify-content-between text-center">
                            <Link
                                to="/"
                                className="flex-column-auto pb-lg-0 pb-10">
                                <img
                                    alt="Logo"
                                    /* className="max-h-100px" */
                                    style={{ maxWidth: 150, width: 150 }}
                                    src={toAbsoluteUrl(
                                        '/media/logos/logo.png'
                                    )}
                                />
                                <img
                                    alt="Logo"
                                    /* className="max-h-100px" */
                                    style={{ maxWidth: 200, marginLeft: 20 }}
                                    src={toAbsoluteUrl(
                                        '/media/logos/logo_text_dark.png'
                                    )}
                                />
                            </Link>

                            <div className="flex-column-fluid d-flex flex-column justify-content-center" style={{ marginTop: '5%' }}>
                                <h3 className="text-dark" style={{ color: '#52575C', fontSize: '3em', marginBottom: 0 }}>
                                    <span style={{ fontWeight: '800', backgroundImage: 'linear-gradient(144deg,#7A287A, #661166 50%,#b503b5)', backgroundClip: 'text', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>Bloom A.I.</span>
                                </h3>
                                {/* <p className="font-weight-light text-dark opacity-80" style={{ color: '#52575C' }}>
                                    The mother of all input components, now compatible with the new Auto Layout Feature.
                                </p> */}
                            </div>

                            {/* <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                                <div className="opacity-70 font-weight-bold	text-white">
                                    &copy; 2021 Bloom
                                </div>
                                <div className="d-flex">
                                    <Link to="/terms" className="text-white">
                                        Términos y condiciones
                                    </Link>
                                    <Link
                                        to="/contact"
                                        className="text-white ml-10">
                                        Contacto
                                    </Link>
                                </div>
                            </div> */}
                        </div>
                    </div>

                    {/*begin::Content*/}
                    <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden" style={{ background: 'linear-gradient(144deg,#7A287A, #661166 50%,#b503b5)', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset' }}>
                        {/*begin::Content header*/}
                        {/* <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
                            <span className="font-weight-bold text-dark-50">
                                Don't have an account yet?
                            </span>
                            <Link
                                to="/auth/registration"
                                className="font-weight-bold ml-2"
                                id="kt_login_signup">
                                Sign Up!
                            </Link>
                        </div> */}
                        {/*end::Content header*/}

                        {/* begin::Content body */}
                        <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                            <Switch>
                                <ContentRoute
                                    path="/auth/login/credentials"
                                    component={LoginCredentials}
                                />
                                <ContentRoute
                                    path="/auth/login"
                                    component={Login}
                                />
                                <Redirect
                                    from="/auth"
                                    exact={true}
                                    to="/auth/login"
                                />
                                <Redirect to="/auth/login" />
                            </Switch>
                        </div>
                        {/*end::Content body*/}

                        {/* begin::Mobile footer */}
                        {/* <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
                            <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                                &copy; 2020 Metronic
                            </div>
                            <div className="d-flex order-1 order-sm-2 my-2">
                                <Link
                                    to="/terms"
                                    className="text-dark-75 text-hover-primary">
                                    Privacy
                                </Link>
                                <Link
                                    to="/terms"
                                    className="text-dark-75 text-hover-primary ml-4">
                                    Legal
                                </Link>
                                <Link
                                    to="/terms"
                                    className="text-dark-75 text-hover-primary ml-4">
                                    Contact
                                </Link>
                            </div>
                        </div> */}
                        {/* end::Mobile footer */}
                    </div>
                    {/*end::Content*/}
                </div>
                {/*end::Login*/}
            </div>
        </>
    )
}
