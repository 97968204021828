import { ApiService, encodeQueryData, NormalizeResponse, SERVICES } from '../../BaseService';

export interface ICommonFilters {
    period_ids?: number[],
    acopio_ids?: number[],
    unit_type?: string,
    transaction_type_ids?: number[],
    transaction_type_id?: number,
    forecast_object_type_id?: number,
    product_group_id?: number,
    date_from?: string[] | string,
    date_to?: string[] | string
}

export const AcopioService = {
    get: (filters: any = {}) => {
        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`acopios?${encodeQueryData(filters)}`));
    }
};
