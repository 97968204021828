import autoSave from '../autoStore';
import { makeAutoObservable } from "mobx"
import { WorkPackages } from 'app/services';

class ProductStore {
    supplies: any[] = []
    grains: any[] = []

    constructor() {
        makeAutoObservable(this)
        autoSave(this);
    }

    setGrains(grainList: any[]) {
        this.grains = grainList;
    }

    getGrains(filters?: any, reload?: boolean) {
        if(reload || this.grains.length == 0) {
            return WorkPackages.BusinessWorkPackageService.ProductService.getGrains().then((data: any) => {
                let grains = data?.data;
                this.grains = grains;

                return grains;
            });
        }

        return new Promise((resolve, reject) => resolve(this.grains));
    }

    setSupplies(suppliesList: any[]) {
        this.supplies = suppliesList;
    }

    getSupplies(filters?: any, reload?: boolean) {
        if(reload || this.supplies.length == 0) {
            return WorkPackages.BusinessWorkPackageService.ProductService.getRubrosGroups().then((data: any) => {
                let supplies = data?.data;
                this.supplies = supplies;

                return supplies;
            });
        }

        return new Promise((resolve, reject) => resolve(this.supplies));
    }
}

const product = new ProductStore();

export {
    product
};