// @ts-ignore
import { appInsights } from 'app/config/AppInsights'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import { useStores } from 'app/mobx/useStore'
import { getVersion } from 'app/utils/Version'
import { useEffect } from 'react'

function capitalize(str: any) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

const queue: any[] = []
// Queue of events in case all the data was not loaded correctly

export function useAnalytics() {
    const stores = useStores()
    const { user } = useSelector((store: any) => store.auth, shallowEqual)

    useEffect(() => {
        const interval = setInterval(() => {
            for (var idx in queue) {
                const index: number = parseInt(idx)
                const item = queue[idx]
                /* console.log(`[Tracking] Processing queue event`, item); */
                Hook.trackEvent(item.eventName, item.params)
                _.remove(queue, (item: any, idx: number) => index == idx)
            }
        }, 5 * 1000)

        return () => clearInterval(interval)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const Hook = {
        normalizeTracking: () => {
            if (user) {
                appInsights.context.user.id = user.id.toString()
                appInsights.context.user.authenticatedId = user.id.toString()
                appInsights.context.user.accountId = user.email
            }
        },
        getDebugInformation: () => {
            return {
                environment: process.env.REACT_APP_ENV,
            }
        },
        trackEvent: (eventName: string, params: any) => {
            if (!user) return
            Hook.normalizeTracking()

            try {
                const source: any = _.find(stores.source.sources, (i: any) => i.id == user.wp_data.source_id)
                const roles =
                    stores.user.canActAsRegional() && stores.user.isActingAsRegional()
                        ? ['Regional']
                        : user.roles_list.map((i: any) =>
                              i.slug
                                  .split(' ')
                                  .map(capitalize)
                                  .join(' ')
                          )

                // Make customer object
                const regions = source?.groups?.map((i: any) =>
                    i.source_group_parent_code
                        .split(' ')
                        .map(capitalize)
                        .join(' ')
                )
                const zones = source?.groups?.map((i: any) =>
                    i.source_group_desc
                        .split(' ')
                        .map(capitalize)
                        .join(' ')
                )
                const customer: any = { email: user.email, roles: roles }
                if (regions) customer.regions = regions
                if (zones) customer.zones = zones

                params = {
                    ...params,
                    customer: customer,
                    version: getVersion(),
                }

                if (!stores.source.sources?.length) {
                    queue.push({
                        eventName,
                        params,
                    })

                    return
                }

                if (process.env.REACT_APP_ENV !== 'production') {
                    /* console.log(`[DEBUG] Tracking event`, eventName, params, user) */
                }

                if (!params) params = {}

                console.log(`[DEBUG] Tracking event`, eventName, params);
                appInsights.trackEvent(
                    {
                        name: eventName,
                    },
                    {
                        ...params,
                        debug: Hook.getDebugInformation(),
                    }
                )
            } catch (e) {
                console.error(`[Tracking] Error tracking event`, e)
            }
        },
        trackPageView: (uri?: string) => {
            Hook.normalizeTracking()
            appInsights.trackPageView({
                uri: uri,
                properties: {
                    debug: Hook.getDebugInformation(),
                },
            })
        },
    }

    return Hook
}
