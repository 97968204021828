import * as URL from './URL';
import * as Numbers from './Numbers';
import * as FileUtils from './FileUtils';
import * as String from './String';
import * as Date from './Date';
import * as Array from './Array';

const Utils = {
    URL,
    Numbers,
    FileUtils,
    String,
    Date,
    Array
};

export default Utils;