import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import * as auth from '../_redux/authRedux'
import { login } from '../_redux/authCrud'
import { useAnalytics } from 'app/hooks'
import { appInsights } from 'app/config/AppInsights'
import { UserService } from 'app/services'
import { removeAccessToken, SERVICES, setAccessToken } from 'app/services/BaseService';
import { toast } from 'react-hot-toast'

import Utils from 'app/utils'
import cookie from 'js-cookie'

const initialValues = {
    email: '',
    password: '',
}

function LoginCredentials(props) {
    const location = useLocation()
    const analytics = useAnalytics()
    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return 'is-invalid'
        }
    }

    const [loading, setLoading] = useState(false)
    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .email('Comprueba el formato del correo electrónico')
            .min(3, 'El campo es requerido')
            .required('El campo es requerido'),
        password: Yup.string()
            .min(3, 'El campo es requerido')
            .required('El campo es requerido'),
    })

    const enableLoading = () => {
        setLoading(true)
    }

    const disableLoading = () => {
        setLoading(false)
    }

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading()

            // Remove access tokens previously to login
            removeAccessToken(SERVICES.CONNECTOR_BACKEND);
            removeAccessToken(SERVICES.WORK_PACKAGE_BUSINESS);

            setTimeout(() => {
                login(values.email, values.password)
                    .then(({ data: { authToken } }) => {
                        disableLoading()
                        if (!authToken) {
                            toast.error(`Los datos de autenticación son incorrectos. Intenta nuevamente`);
                        } else {
                            // Save token
                            const key = `access_token_${SERVICES.CONNECTOR_BACKEND.ID}`
                            cookie.set(key, authToken)
                            setAccessToken(SERVICES.CONNECTOR_BACKEND, authToken);

                            // window.location.href = '/forecasts';
                            props.login(authToken)
                        }
                    })
                    .catch(() => {
                        toast.error(`Los datos de autenticación son incorrectos. Intenta nuevamente`);
                        setStatus('Los datos de autenticación son incorrectos. Intenta nuevamente')
                    })
                    .finally(() => {
                        disableLoading()
                        setSubmitting(false)
                    })
            }, 1000)
        },
    })

    useEffect(() => {
        const params = Utils.URL.getParams(location)
        if (params.access_token) {
            enableLoading()

            const authToken = params.access_token

            // Save token
            const key = `access_token_${SERVICES.CONNECTOR_BACKEND.ID}`
            cookie.set(key, authToken)
            cookie.set(`auth_provider`, params.provider || 'azure');

            UserService.getMe().then(data => {
                if (data.status == 200) {
                    const { user } = data.data
                    appInsights.context.user.id = user.id.toString()
                    appInsights.context.user.authenticatedId = user.id.toString()
                    appInsights.context.user.accountId = user.email
                    analytics.trackEvent('Authentication', {
                        type: 'Login',
                    })
                    setAccessToken(SERVICES.WORK_PACKAGE_BUSINESS, data.data.wp_token);
                    cookie.set(`access_token_${SERVICES.WORK_PACKAGE_BUSINESS}`, data.data.wp_token)
                }

                props.login(authToken)
            }).catch((err) => {
                if (err) {
                    // Remove the token and throw an alert
                    toast.error(`Hubo un problema al iniciar sesión. Por favor, inténtalo nuevamente.`);
                    removeAccessToken(SERVICES.CONNECTOR_BACKEND);
                    setLoading(false);
                }
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="login-form login-signin" id="kt_login_signin_form">
            {/* begin::Head */}
            <div className="text-center mb-2 mb-lg-2">
                <p className="text-muted font-weight-bold">
                    Ingresa tu dirección de correo electrónico y tu contraseña para continuar
                </p>
            </div>
            {/* end::Head */}

            <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework">
                {formik.status && (
                    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                        <div className="alert-text font-weight-bold">
                            {formik.status}
                        </div>
                    </div>
                )}

                <div className="form-group fv-plugins-icon-container" style={{ marginBottom: 5 }}>
                    <input
                        autoFocus
                        placeholder="Email"
                        type="email"
                        className={`form-control form-control-solid h-auto py-6 px-6 ${getInputClasses(
                            'email'
                        )}`}
                        name="email"
                        {...formik.getFieldProps('email')}
                        style={{ fontSize: '1.2em' }}
                    />
                    {formik.touched.email && formik.errors.email ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.email}
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder="Password"
                        type="password"
                        className={`form-control form-control-solid h-auto py-6 px-6 ${getInputClasses(
                            'password'
                        )}`}
                        name="password"
                        {...formik.getFieldProps('password')}
                        style={{ fontSize: '1.2em' }}
                    />
                    {formik.touched.password && formik.errors.password ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.password}
                            </div>
                        </div>
                    ) : null}
                </div>

                <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={formik.isSubmitting}
                    className={`btn bg-contrast raise font-weight-bold px-9 py-4 my-2 w-100 no-border-hover no-border`}
                    style={{ opacity: loading ? 0.5 : 1 }}>
                    <span style={{ fontSize: '1.2em', fontWeight: 400, color: '#222' }}>Ingresar con usuario</span>

                    {loading && <span className="ml-3 spinner spinner-white"></span>}
                </button>
            </form>
            {/*end::Form*/}
        </div>
    )
}

export default connect(null, auth.actions)(LoginCredentials)