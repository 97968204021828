import { useEffect } from 'react'
// @ts-ignore
import { shallowEqual, useSelector } from 'react-redux';
import _ from 'lodash';

var LOCALIZATION_FILE: any = null;

export function useLocalization() {
    const { user } = useSelector((state: any) => state.auth, shallowEqual);

    useEffect(() => {
        if(user) {
            const organization: any = _.first(user.organizations);
            if(organization) {
                try {
                    LOCALIZATION_FILE = require(`app/localization/projects/${organization.slug}.json`);
                } catch (err) {
                    LOCALIZATION_FILE = require(`app/localization/projects/upl.json`);
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return {
        t: (text: string, defaultValue?: string) => {
            if(!defaultValue) defaultValue = text;

            // @ts-ignore
            /* if(localizationFile && localizationFile[text]) {
                // @ts-ignore
                return localizationFile[text] || defaultValue;
            } */

            // @ts-ignore
            if(LOCALIZATION_FILE) {
                // @ts-ignore
                return LOCALIZATION_FILE[text] || defaultValue;
            }

            return defaultValue;
        },
        tr: (text: string, replacements?: any[]) => {
            // @ts-ignore
            if(LOCALIZATION_FILE) {
                // @ts-ignore
                let textElement = LOCALIZATION_FILE[text];
                if(replacements && replacements.length > 0) {
                    if(textElement.includes('%0')) {
                        textElement = textElement.replace('%0', replacements[0] || '-')
                    } else if(textElement.includes('%1')) {
                        textElement = textElement.replace('%1', replacements[1] || '-')
                    };
                }

                return textElement;
            }

            return null;
        },
        file: () => {
            const organization: any = _.first(user.organizations);
            return require(`app/localization/projects/${organization.slug}.json`)
        }
    };
}