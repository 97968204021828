import React, { useEffect, useState } from 'react'

import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

// @ts-ignore
import OverlayLoading from 'app/components/OverlayLoading'
import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client'
import { toast } from 'react-hot-toast'
import Tooltip from 'app/components/Tooltip'
import { getBaseUrl, SERVICES } from 'app/services/BaseService'
import { useAnalytics } from 'app/hooks'

export function ImportsDashboardPage() {
    const analytics = useAnalytics()
    const [loading, setLoading] = useState<boolean>(false)
    const [embedData, setEmbedData] = useState<any>(null)
    const [reportId, setReportId] = useState<any>(`612d5fec-2103-4ab3-9e10-73e6738d9726`)
    const [datasetId, setDatasetId] = useState<any>(`c9edbb2d-b4df-4675-90fb-070091dde095`)
    const [workspaceId, setWorkspaceId] = useState<any>(`4dd6453c-8861-4074-af90-1b2febc4a935`)

    useEffect(() => {
        setLoading(true)

        let identities: any[] = []
        let REPORT_ID: string = reportId
        let DATASET_ID: string = datasetId
        let WORKSPACE_ID: string = workspaceId

        let baseUrl = getBaseUrl(SERVICES.CONNECTOR_BACKEND)
        if (baseUrl.endsWith('/')) baseUrl = baseUrl.slice(0, -1)
        let url = `${baseUrl}/azure/reports/getEmbedToken?reportId=${REPORT_ID}&datasetId=${DATASET_ID}&workspaceId=${WORKSPACE_ID}`

        if (identities && identities.length > 0) url += `&identities=${JSON.stringify(identities)}`

        // Set variables
        setReportId(REPORT_ID)
        setDatasetId(DATASET_ID)
        setWorkspaceId(WORKSPACE_ID)

        fetch(url)
            .then(res => res.json())
            .then(result => {
                if (result != null) {
                    analytics.trackEvent('Navigation', {
                        type: window.location.pathname,
                    })

                    setEmbedData({
                        accessToken: result.token,
                        embedUrl: result.embedUrl,
                        reportId: result.reportId,
                    })
                }
            })
            .catch((err: any) => {
                toast.error('Error al cargar el reporte. Inténtelo nuevamente en unos minutos.')
            })
            .finally(() => {
                setLoading(false)
            })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div style={{ position: 'relative', padding: 10 }}>
            <h1 style={{ marginBottom: 20 }}>
                Dashboard Abastecimiento
                <Tooltip
                    title={
                        <>
                            <p className={'m-2 text-center'}>
                                ID de reporte: <b>{reportId}</b>
                            </p>
                            <p className={'m-2 text-center'}>
                                ID de dataset: <b>{datasetId}</b>
                            </p>
                            <p className={'m-0 text-center'}>
                                ID de workspace: <b>{workspaceId}</b>
                            </p>
                        </>
                    }>
                    <HelpOutlineIcon style={{ marginLeft: 10, fontSize: '0.9em', color: 'rgba(0,0,0,.5)' }} />
                </Tooltip>
            </h1>

            {embedData != null && (
                <PowerBIEmbed
                    embedConfig={{
                        type: 'report', // Supported types: report, dashboard, tile, visual and qna
                        id: embedData.reportId,
                        embedUrl: embedData.embedUrl,
                        accessToken: embedData.accessToken,
                        tokenType: models.TokenType.Embed,
                        settings: {
                            panes: {
                                filters: {
                                    expanded: false,
                                    visible: false,
                                },
                            },
                            background: models.BackgroundType.Transparent,
                        },
                    }}
                    eventHandlers={
                        new Map([
                            ['loaded', (event: any, embeddedReport: any) => {}],
                            [
                                'rendered',
                                (event: any, embeddedReport: any) => {
                                    setLoading(false)
                                },
                            ],
                            [
                                'error',
                                (event: any) => {
                                    /* console.log(event.detail); */
                                },
                            ],
                            [
                                'visualClicked',
                                (event: any) => {
                                    console.log('Visual clicked', event)
                                },
                            ],
                            [
                                'dataSelected',
                                (event: any, embeddedReport: any) => {
                                    console.log('Data selected', event)
                                },
                            ],
                            [
                                'selectionChanged',
                                (event: any) => {
                                    console.log('Selection changed', event)
                                },
                            ],
                        ])
                    }
                    cssClassName={'report-style-class'}
                    getEmbeddedComponent={embeddedReport => {}}
                />
            )}

            <OverlayLoading visible={loading} />
        </div>
    )
}
