/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
/* import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage"; */
import { useHistory, useLocation } from 'react-router'

export function Routes() {
    const location = useLocation();
    const history = useHistory();
    const { isAuthorized } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.user != null,
        }),
        shallowEqual
    );
    const { user } = useSelector((state) => state.auth, shallowEqual);

    useEffect(() => {
        if(!isAuthorized) {
            if(!location.pathname.includes('auth/login') && !location.pathname.includes('logout')) {
                localStorage.setItem('REDIRECTION_AFTER_AUTH', `${location.pathname + location.search}`)
            }
        } else {
            if(localStorage.getItem('REDIRECTION_AFTER_AUTH')) {
                const redirectionAfterAuth = localStorage.getItem('REDIRECTION_AFTER_AUTH');
                if(!redirectionAfterAuth.includes('login') && !redirectionAfterAuth.includes('logout')) {
                    history.push(redirectionAfterAuth);
                    localStorage.removeItem('REDIRECTION_AFTER_AUTH');
                }
            }
        }
    }, [history, location, isAuthorized]);

    const isAuthorizedAccess = (isAuthorized || location.pathname.includes('poc'));

    if(!isAuthorizedAccess) {
        return (
            <>
                <Route>
                    <AuthPage />
                </Route>
                {/* <Redirect to="/auth/login" /> */}
            </>
        );
    }

    return (
        <Switch>
            <Redirect from="/auth" to="/" />
            {/* <Route path="/error" component={ErrorsPage} /> */}
            <Route path="/logout" component={Logout} />

            {!isAuthorized ? (
                <>
                    <Redirect to="/auth/login" />
                </>
            ) : (
                location.pathname === "/dashboard" && user.role.slug === "administrator" ? 
                (
                    <Redirect to="/" />
                ) : (
                    <Layout>
                        <BasePage />
                    </Layout>
                )
                
            )}
        </Switch>
    );
}
