import { encodeQueryData } from 'app/services/BaseService';
import qs from 'qs';

export function getParams(location: any): any {
    return qs.parse(location.search, { ignoreQueryPrefix: true });
}

export function getParam(location: any, param: string, defaultValue?: any): any {
    const paramValue = qs.parse(location.search, { ignoreQueryPrefix: true })[param];
    if(!paramValue) return defaultValue;

    return paramValue;
}

export function setParam(location: any, history: any, param: string, value: any) {
    let params = qs.parse(location.search, { ignoreQueryPrefix: true });
    if(params != null) {
        params[param] = value;
    }

    history.replace(`?${encodeQueryData(params)}`);
}

export function setParams(location: any, history: any, parameters: any, append?: boolean) {
    let params: any = {};
    if(append) {
        params = qs.parse(location.search, { ignoreQueryPrefix: true });
    }

    if(params != null) {
        for(var idx in parameters) {
            params[idx] = parameters[idx];
        }
    }

    history.replace(`?${encodeQueryData(params)}`);
}

export function removeParam(location: any, history: any, param: string) {
    let params = qs.parse(location.search, { ignoreQueryPrefix: true });
    delete params[param];

    history.replace(`?${encodeQueryData(params)}`);
}

export function setUrlWithoutReloading(url?: string, params?: any) {
    /* window.history.replaceState(null, 'Bloom', `${url}${encodeQueryData(params)}`); */
}