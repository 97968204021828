// AppInsights.js
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: '2b70c721-3ef6-4930-bd43-c47d692c8367',
        extensions: [reactPlugin],
        disableAjaxTracking: true,
        maxAjaxCallsPerView: 0,
        autoTrackPageVisitTime: true,
        enableAutoRouteTracking: true
    }
});
appInsights.loadAppInsights();
export { reactPlugin, appInsights };