export const toArray = (value: any) => {
    if(!Array.isArray(value))
        return [value];

    return value;
}

export const upsert = (array: any, predicate: any, element: any) => {
    const i = array.findIndex(predicate);
    if (i > -1) array[i] = element; // (2)
    else array.push(element);
}