import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Fade } from 'react-bootstrap';

export default function OverlayComponent(props: any) {
    const [mounted, isMounted] = useState(false);
    useEffect(() => {
        if(props.visible) {
            isMounted(true);
        } else {
            isMounted(false);
        }
    }, [props.visible]);

    if(!mounted) return null;
    
    return (
        <Fade in={props.visible}>
            <div
                className="overlay-component-item"
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.75)', zIndex: 15 }}>
                <CircularProgress
                    style={{ color: '#f2f2f2' }}
                />
            </div>
        </Fade>
    );
}