import autoSave from '../autoStore';
import { makeAutoObservable } from "mobx"
import moment from 'moment';

class ConfigurationStore {
    configuration: any = {
        unit: 'transaction_amount',
        date_from: null,
        date_to: null
    }

    dates: any = {
        periods: [],
        months: [],
        quarters: [],

        dates_from: [],
        dates_to: []
    }

    constructor() {
        makeAutoObservable(this)
        autoSave(this);
    }

    /*
    *   GETTERS & SETTERS
    */

    setUnit(unit: string) {
        this.configuration = {
            ...this.configuration,
            unit: unit
        };
    }
    getUnit = () => this.configuration.unit;
    getUnitText = () => this.configuration.unit == 'transaction_amount' ? 'USD' : 'KG-LT';

    /*
    *   Dates
    */

    setDateFrom(date: any) {
        this.configuration.date_from = [date];
    }

    setDateTo(date: any) {
        this.configuration.date_to = [date];
    }

    getDateFrom = () => moment(this.configuration.date_from[0]);
    getDateFromFormatted = () => moment(this.configuration.date_from[0]).format('YYYY-MM-DD HH:mm');
    getDateToFormatted = () => moment(this.configuration.date_to[0]).format('YYYY-MM-DD HH:mm');
    getDateTo = () => moment(this.configuration.date_to[0]);
    isEmptyDates = (): boolean => !this.configuration.date_to || !this.configuration.date_to.length || !this.configuration.date_from || !this.configuration.date_from.length;
    isEmptyDateFrom = (): boolean => !this.configuration.date_from || !this.configuration.date_from.length;
    isEmptyDateTo = (): boolean => !this.configuration.date_to || !this.configuration.date_to.length;

    // Set quarters
    setPeriods(periods: string[]) {
        this.dates = {
            ...this.dates,
            periods: periods
        };
    }

    setQuarters(quarters: number[]) {
        this.dates = {
            ...this.dates,
            quarters: quarters
        };
    }

    setMonths(months: number[]) {
        this.dates = {
            ...this.dates,
            months: months
        };
    }

    setDatesFromRequest(datesFrom: string[]) {
        this.dates = {
            ...this.dates,
            dates_from: datesFrom
        };
    }

    setDatesToRequest(datesTo: string[]) {
        this.dates = {
            ...this.dates,
            dates_to: datesTo
        };
    }

    getDatesFromRequest() {
        return this.dates.dates_from;
    }

    getDatesToRequest() {
        return this.dates.dates_to;
    }
}

const configuration = new ConfigurationStore();

export {
    configuration
};