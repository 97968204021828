/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers'

export function AsideMenuListSuperAdministrator() {
    const location = useLocation()
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu &&
                  'menu-item-active'} menu-item-open menu-item-not-hightlighted`
            : ''
    }

    return (
        <>
            <ul className={`menu-nav`}>
                <li
                    className={`menu-item ${getMenuItemActive(
                        '/admin/organizations',
                        false
                    )}`}
                    aria-haspopup="true">
                    <NavLink className="menu-link" to="/admin/organizations">
                        <span className="svg-icon menu-icon">
                            <SVG
                                src={toAbsoluteUrl(
                                    '/media/svg/icons/Design/Layers.svg'
                                )}
                            />
                        </span>
                        <span className="menu-text">Organizaciones</span>
                    </NavLink>
                </li>
            </ul>
        </>
    )
}
