import { ICreatePayloadStock, IForecastConfiguration } from 'app/interfaces/Forecast'
import { ApiService, encodeQueryData, NormalizeResponse, SERVICES } from '../../BaseService'
import _ from 'lodash'

const roles = [
    { id: 1, name: 'RTV', description: 'RTV' },
    { id: 2, name: 'REGIONAL', description: 'Validación Regional' },
    { id: 3, name: 'SALES', description: 'Administración de ventas' },
    { id: 4, name: 'SUPPLY', description: 'Supply' },
    { id: 5, name: 'VERIFICATION_REGIONAL_SUPPLY', description: 'Verificación de Stock Regional' },
    { id: 6, name: 'VERIFICATION_SUPPLY', description: 'Verificación de Stock' },
    { id: 7, name: 'FINANCE', description: 'Consolidación del Forecast' },
]

export const ForecastService = {
    getRoles: () => {
        const obj: any = {}
        _.map(roles, (i: any) => {
            obj[i.name] = i.id
        })

        return obj
    },
    getForecastProgress: () => {
        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`forecast/progress`))
    },
    getSourceGroups: (filters: any = {}) => {
        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`forecast/source-groups?${encodeQueryData(filters)}`))
    },
    getProducts: (filters: any = {}) => {
        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`forecast/products?${encodeQueryData(filters)}`))
    },
    getProductPricing: (productIds: number[] | number, filters: any) => {
        /* let productIdsString = '';

        if(Array.isArray(productIds)) {
            for(var idx in productIds) {
                productIdsString += `product_id=${productIds[idx]}&`;
            }
        } else {
            productIdsString += `product_id=${productIds}&`;
        } */

        return NormalizeResponse(
            ApiService(SERVICES.WORK_PACKAGE_BUSINESS).post(`forecast/products/pricing?${encodeQueryData(filters)}`, {
                product_ids: productIds,
            })
        )
    },
    getForecast: (idForecast: number) => {
        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`forecast/detail/${idForecast}`))
    },
    getForecastRevisions: (idForecast: number) => {
        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`forecast/revisions/${idForecast}`))
    },
    getForecasts: (filters?: any) => {
        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`forecast?${encodeQueryData(filters)}`))
    },
    getForecastsSabana: (filters?: any) => {
        let url = 'forecast/sabana'
        if (filters) url += `?${encodeQueryData(filters)}`
        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(url))
    },
    postBulkIncrementWorkflowStep: (idStep: number, temporal_forecast_ids: number[]) => {
        return NormalizeResponse(
            ApiService(SERVICES.WORK_PACKAGE_BUSINESS).put(`forecast/workflow-step/${idStep}`, {
                temporal_forecast_ids: _.uniq(temporal_forecast_ids),
            })
        )
    },
    submitForecast: (payload: any = {}) => {
        if (!Array.isArray(payload)) payload = [payload]

        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).post(`forecast`, payload))
    },
    getForecastConfiguration: () => {
        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`forecast/configuration`))
    },
    updateForecastConfiguration: (payload: IForecastConfiguration) => {
        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).post(`forecast/configuration`, payload))
    },
    consolidate: (temporalForecastIds: number[]) => {
        return NormalizeResponse(
            ApiService(SERVICES.WORK_PACKAGE_BUSINESS).post(`forecast/consolidation`, {
                temporal_forecast_ids: temporalForecastIds,
            })
        )
    },
    getVerificationStockDetails: () => {
        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).post(`forecast/detail/stock`))
    },
    submitVerificationStockDetails: (payload: any, submit: boolean = false) => {
        let url = `forecast/stock`
        if (submit) url += `?submit=true`

        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).put(url, payload))
    },
    getStock: (periodId: number = 11) => {
        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`forecast/stock?period_id=${periodId}`))
    },
    createStock: (payload: ICreatePayloadStock[], isSubmitting: boolean = false) => {
        let url = `forecast/stock`
        if (isSubmitting) url += `?submit=true`

        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).post(url, payload))
    },
    getForecastRoleById: (id: number) => {
        return _.find(roles, (i: any) => i.id === id)
    },
    getForecastBonus: (params: any) => {
        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`forecast/bonus?${encodeQueryData(params)}`))
    },
    getConsolidated: (params: any) => {
        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`forecast/consolidated?${encodeQueryData(params)}`))
    },
    downloadSupplyFile: () => {
        return NormalizeResponse(
            ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`forecast/detail/stock?download=true`, {
                responseType: 'blob',
            })
        )
    },
    uploadForecastFile: (file: File, stepNumber: number) => {
        var FormData = require('form-data')
        var data = new FormData()
        data.append('forecast-file', file)

        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS, 60 * 1000, { retries: 1 }).put(`forecast/file/${stepNumber}`, data))
    },
    uploadSupplyFile: (file: File) => {
        var FormData = require('form-data')
        var data = new FormData()
        data.append('supply-file', file)

        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).post(`forecast/detail/stock`, data))
    },
    updateVolumesByProduct: (productId: number, payload: any) => {
        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).put(`forecast/product/${productId}/volume`, payload))
    },
    getMissingSourcesForCompletion: (filters: any = {}) => {
        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`alerts/missing-rtvs?${encodeQueryData(filters)}`))
    },
    getMissingRegionalsForCompletion: (filters: any = {}) => {
        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`alerts/missing-regionals?${encodeQueryData(filters)}`))
    },
}
