import autoSave from '../autoStore';
import { makeAutoObservable } from "mobx"

class ForecastStore {
    forecasts: any[] = []
    forecastDate: any;

    constructor() {
        makeAutoObservable(this)
        autoSave(this);
    }

    set(forecasts: any[]) {
        this.forecasts = forecasts;
    }

    get() {
        return this.forecasts;
    }

    setForecastDate(forecastDate: any) {
        this.forecastDate = forecastDate;
    }

    getForecastDate() {
        return this.forecastDate;
    }
}

const forecast = new ForecastStore();
export {
    forecast
};