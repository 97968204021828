import { ApiService, encodeQueryData, getAccessToken, getBaseUrl, NormalizeResponse, SERVICES } from '../../BaseService';
import _ from 'lodash';

export const TransactionService = {
    get: () => {
        return ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`transactions`);
    },
    getById: (id: number) => {
        return ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`transactions/${id}`);
    },
    getTransactionsByRubros: (filters?: any) => {
        // let url = `transactions/by_rubros/total?`;
        let url = `level-two/sales-by-product-group?`;

        if(filters) {
            url += `${encodeQueryData(filters)}`;
        }

        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(url));
    },
    getTransactionsByGrains: (filters?: any) => {
        let url = `transactions/by_grains/total?`;
        if(filters) {
            url += `${encodeQueryData(filters)}`;
        }

        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(url));
    },
    getLastUpdate: () => {
        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`transactions/last_update`));
    },
    getExportTransactions: (acopioId: number | number[], filters: any, format: string) => {
        let acopioIds: any = acopioId;
        let url = `${getBaseUrl(SERVICES.WORK_PACKAGE_BUSINESS)}clients/acopio/summary?export=${format}&access_token=${getAccessToken(SERVICES.WORK_PACKAGE_BUSINESS)}&`;

        if(Array.isArray(acopioIds)) {
            acopioIds = _.map(acopioIds, i => {
                return `acopio_ids=${i}`;
            }).join('&');

            url += `${acopioIds}&`;
        } else {
            url += `&acopio_ids=${acopioIds}&`;
        }

        url += `${encodeQueryData(filters)}`;

        return url;
    },
    getExportClientDetails: (clientId: number, transactionTypeId: number, filters: any, format: string) => {
        let url = `${getBaseUrl(SERVICES.WORK_PACKAGE_BUSINESS)}clients/${clientId}/transactions/${transactionTypeId == 1 ? 'supplies' : 'grains'}?export=${format}&access_token=${getAccessToken(SERVICES.WORK_PACKAGE_BUSINESS)}&`;
        url += `${encodeQueryData(filters)}`;

        if(filters.mode == 'monthly') {
            url = `${getBaseUrl(SERVICES.WORK_PACKAGE_BUSINESS)}clients/${clientId}/transactions/${transactionTypeId == 1 ? 'supplies' : 'supplies'}/monthly?transaction_type_id=${transactionTypeId}&period_ids=${filters.period_ids[0]}&acopio_ids=${filters.acopio_ids[0]}&export=${format}&access_token=${getAccessToken(SERVICES.WORK_PACKAGE_BUSINESS)}&${encodeQueryData(filters)}`;
        }

        return url;
    },
    isSupplies: (typeId?: number) => {
        if(!typeId) typeId = 1;
        return typeId == TransactionService.TYPES().SUPPLIES
    },
    TYPES: () => {
        return {
            UNKNOWN: -1, // This is when the business plan maybe is enabled
            SUPPLIES: 1,
            GRAINS: 2
        }
    },
    UNIT_TYPES: () => {
        return {
            UNITS: 'transaction_units',
            AMOUNT: 'transaction_amount'
        }
    },
    OBJECT_TYPES: () => {
        return {
            FORECAST: 1,
            BUSINESS_PLAN: 2
        }
    },
    SALE_TYPE_ID: () => {
        return {
            NA: -1,
            ESTRATEGICO: 1,
            PORTFOLIO: 2
        }
    }
};
