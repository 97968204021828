import { ApiService, getBaseUrl, SERVICES } from './BaseService';

export const AuthService = {
    login: (data: any) => {
        return ApiService(SERVICES.CONNECTOR_BACKEND).post('auth/login', data);
    },
    requestOktaLink: () => {
        return ApiService(SERVICES.CONNECTOR_BACKEND).get('auth/okta');
    },
    loginViaId: (id: any) => {
        return ApiService(SERVICES.CONNECTOR_BACKEND).post(`auth/login-via-id`, {
            id
        });
    },
    loginViaSourceId: (id: any) => {
        return ApiService(SERVICES.CONNECTOR_BACKEND).post(`auth/login-via-source-id`, {
            id
        });
    },
    resetPassword: (userId: any, password: string) => {
        return ApiService(SERVICES.CONNECTOR_BACKEND).post(`auth/reset_password`, {
            user_id: userId,
            password
        });
    },
    getUrlLogin: () => {
        return getBaseUrl(SERVICES.CONNECTOR_BACKEND) + `auth/login-ad?redirect=${getBaseUrl(SERVICES.FRONT_URL)}/auth/login`;
    },
    logout: () => {
        return ApiService(SERVICES.CONNECTOR_BACKEND).post('auth/logout');
    }
}