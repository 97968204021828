/* ts-disable */
/* @ts-disable */
/* eslint-disable */
/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import cookie from 'js-cookie';
import { getEnvironment, getVersion } from 'app/utils/Version';
import { useSelector } from 'react-redux'
import { toAbsoluteUrl } from '../../../../_helpers'
import { DropdownTopbarItemToggler } from '../../../../_partials/dropdowns'
import { UserService } from 'app/services'
import { useAnalytics } from 'app/hooks';
import { getAccessToken, getBaseUrl, SERVICES, setAccessToken, setBaseURL } from 'app/services/BaseService';
import { AuthService } from 'app/services/auth.service';
import { useStores } from 'app/mobx/useStore';
import Tooltip from 'app/components/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { observe } from 'mobx';
import SVG from "react-inlinesvg";
import { PricingService } from 'app/services/work_packages/business/pricing.service';
import moment from 'moment';

const ENABLE_REGIONALS_CHANGE_SESSION = true;

export function UserProfileDropdown() {
    const [loggedUser, setLoggedUser] = useState(null);
    const [sourceGroups, setSourceGroups] = useState([]);
    const { user } = useSelector(state => state.auth)
    const analytics = useAnalytics();
    const stores = useStores();
    const [managerOf, setManagerOf] = useState(null);

    useEffect(() => {
        UserService.getMe().then((data) => {
            if (data.status == 200) {
                if (data.data && data.data.success) {
                    const userData = data.data.user;
                    userData.wp_data = data.data.wp_data;
                    localStorage.setItem("user", JSON.stringify(userData));
                    setAccessToken(SERVICES.WORK_PACKAGE_BUSINESS, data.data.wp_token);
                    setLoggedUser(data.data.user);
                    stores.user.setUser(data.data.user);

                    stores.source.getByIdentityCodes(_.map(user.sources, i => i.source_identity_code)).then((sources) => {
                        if (sources && sources.length)
                            setSourceGroups(_.flatten(_.map(sources, i => i.groups)));
                    });
                }
            }
        });

        PricingService.getCompositionDate().then((data) => {
            stores.forecast.setForecastDate(moment(data.date).utc().format('YYYY-MM-DD [22:00]'));
        });

        observe(stores.source, i => {
            const source = stores.source.getMySource();
            if (source && ENABLE_REGIONALS_CHANGE_SESSION) {
                setManagerOf(source.managerOf);
                stores.user.setManagerOf(source.managerOf);
            } else {
                setManagerOf([]);
                stores.user.setManagerOf([]);
            }
        });
    }, []);

    const onLogout = () => {
        const authProvider = cookie.get(`auth_provider`);
        const isAzure = authProvider == 'azure';

        const _logout = () => {
            analytics.trackEvent('Authentication', {
                type: 'Logout'
            });

            localStorage.removeItem('store');
            localStorage.removeItem('manager_user');
        }

        _logout();
        window.location.href = "/logout";
    }

    const getManagerUser = () => {
        const managerUser = localStorage.getItem("manager_user");
        if (managerUser) {
            return JSON.parse(managerUser);
        }

        return null;
    }

    return (
        <Dropdown drop="down" alignRight>
            <Dropdown.Toggle
                as={DropdownTopbarItemToggler}
                id="dropdown-toggle-user-profile">
                <div
                    className={
                        'btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2'
                    }
                    style={{ background: 'rgba(0,0,0,0)', border: '2px dashed rgba(0,0,0,0.1)' }}>
                    <SVG
                        src={toAbsoluteUrl(
                            '/media/svg/icons/Shopping/settings.svg'
                        )}
                        className='icon-user'
                        style={{ maxHeight: '100%', maxWidth: 30 }}
                    />
                    <div className='d-flex flex-column'>
                        <span className="font-weight-bolder font-size-base d-none d-md-inline ml-3" style={{ color: '#6e126f' }}>
                            {loggedUser != null && loggedUser.email ? loggedUser.email : 'Usuario'}
                        </span>
                        <span className="font-size-base d-none d-md-inline ml-3">
                            {managerOf && managerOf.length > 0 ? (stores.user.isActingAsRegional() ? 'Regional' : 'RTV') : ''}
                        </span>
                    </div>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
                <>
                    <div className={'p-4 bgi-size-cover bgi-no-repeat rounded-top bg-primary'}>
                        <div
                            className="d-flex align-items-center justify-content-between flex-wrap bg-primary"
                            style={{
                                width: '100%',
                                backgroundImage: `url(${toAbsoluteUrl(
                                    '/media/misc/bg-1.jpg'
                                )})`,
                            }}>
                            <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                                {managerOf && managerOf.length > 0 ?
                                    <select
                                        onChange={(evt) => {
                                            const value = evt.target.value;
                                            if (value > -1) {
                                                AuthService.loginViaSourceId(value).then((data) => {
                                                    if (data.status == 200 && data.data.success) {
                                                        localStorage.setItem("manager_user", JSON.stringify({
                                                            access_token: getAccessToken(SERVICES.CONNECTOR_BACKEND),
                                                            name: loggedUser.profile.name
                                                        }));
                                                        setAccessToken(SERVICES.CONNECTOR_BACKEND, data.data.access_token);
                                                        stores.user.setActingAsRegional(false);
                                                        window.location.reload();
                                                    }
                                                });
                                            }
                                        }}
                                        style={{
                                            background: 'rgba(255,255,255,.25)',
                                            color: '#444',
                                            border: '1px dashed rgba(0,0,0,.4)',
                                            fontWeight: 400,
                                            padding: 5,
                                            width: '100%',
                                            borderRadius: 5,
                                            maxWidth: '100%',
                                            outline: 'none'
                                        }}
                                        className='switch-user-session'>
                                        <option value={-1}>{loggedUser && loggedUser.profile && loggedUser.profile.name ? loggedUser.profile.name : '-'}</option>
                                        {managerOf.map((option) => {
                                            if (loggedUser && loggedUser.profile && loggedUser.profile.name && option.source_agent_desc === loggedUser.profile.name) return;

                                            return (
                                                <option value={option.source_agent_id}>{option.source_agent_desc}</option>
                                            )
                                        })}
                                    </select>
                                    : (
                                        loggedUser && loggedUser.profile && loggedUser.profile.name ? loggedUser.profile.name : '-'
                                    )}
                            </div>

                            {/* { loggedUser && loggedUser.organizations && loggedUser.organizations.length > 0 &&
                                <span className="label bg-secondary label-lg font-weight-bold label-inline text-white">
                                    { loggedUser.organizations[0].name }
                                </span>
                            } */}
                        </div>

                        {getManagerUser() &&
                            <p style={{ color: 'white', fontSize: 12, marginTop: 5, marginBottom: 0 }}>Estás visualizando información de otro RTV. Para volver a tu sesión original, toca el botón debajo</p>
                        }

                        {sourceGroups && sourceGroups.length > 0 &&
                            <Tooltip title={'Este es el listado de tus zonas asignadas actualmente'}>
                                <div
                                    className={'withTooltip source-groups-user mt-3 text-white font-weight-bold'}
                                    style={{ display: 'inline-block', padding: 5, backgroundColor: 'rgba(255,255,255,.0)', border: '1px dashed rgba(255,255,255,.2)', borderRadius: 5, fontSize: '0.85em', verticalAlign: 'middle' }}>
                                    <HelpOutlineIcon style={{ marginRight: 5, fontSize: '1.5em', marginBottom: 2 }} />
                                    {sourceGroups.map(group => group.source_group_desc).join(', ')}
                                </div>
                            </Tooltip>
                        }
                    </div>
                </>

                <div className="navi navi-spacer-x-0">
                    <div className="navi-footer" style={{ paddingLeft: '1rem', justifyContent: 'flex-start', flexWrap: 'wrap', gap: 10 }}>
                        {getManagerUser() &&
                            <a
                                onClick={() => {
                                    setAccessToken(SERVICES.CONNECTOR_BACKEND, getManagerUser().access_token);
                                    localStorage.removeItem("manager_user");
                                    stores.user.setActingAsRegional(false);
                                    window.location.reload();
                                }}
                                className="btn btn-primary font-weight-bold mr-4"
                                style={{ minWidth: '100%' }}>
                                Volver a <span style={{ marginLeft: 5, fontWeight: 800 }}>{getManagerUser().name}</span>
                            </a>
                        }

                        <a
                            onClick={onLogout}
                            className="btn btn-light-primary font-weight-bold"
                            style={{ display: 'flex', flex: 1 }}>
                            Salir del sistema
                        </a>

                        {stores.user.canActAsRegional() &&
                            <a
                                onClick={() => {
                                    stores.user.setActingAsRegional(!stores.user.isActingAsRegional());
                                    window.location.reload();
                                }}
                                className="btn btn-light-primary font-weight-bold ml-2"
                                style={{ display: 'flex', flex: 1 }}>
                                Actuar como {stores.user.isActingAsRegional() ? 'RTV' : 'Regional'}
                            </a>
                        }
                    </div>
                </div>

                <div className="navi-footer" style={{ paddingLeft: '1rem', justifyContent: 'flex-start', fontSize: 10, fontWeight: 400, color: 'rgba(0,0,0,.5)', paddingBottom: 10 }}>
                    <p style={{ margin: 0 }}><span style={{ color: 'rgba(0,0,0,0.25)' }}>Version:</span> {getVersion()}</p>
                    <p style={{ margin: 0 }}><span style={{ color: 'rgba(0,0,0,0.25)' }}>
                        Environment:</span> {getEnvironment()}

                        <Tooltip title={
                            <div style={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                <p style={{ margin: 0, fontSize: 10, color: 'rgba(0,0,0,.5)' }}>Backend: {getBaseUrl(SERVICES.WORK_PACKAGE_BUSINESS)}</p>
                                <p style={{ margin: 0, fontSize: 10, color: 'rgba(0,0,0,.5)' }}>WP: {getBaseUrl(SERVICES.CONNECTOR_BACKEND)}</p>
                            </div>
                        }>
                            <HelpOutlineIcon style={{ color: 'rgba(0,0,0,.5)', fontSize: 16, marginLeft: 2, marginBottom: 3 }} />
                        </Tooltip>
                    </p>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    )
}
