/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { AsideMenuList } from './AsideMenuList'
import { AsideMenuListAdministrator } from './AsideMenuListAdministrator'
import { AsideMenuListSuperAdministrator } from './AsideMenuListSuperAdministrator'
import { shallowEqual, useSelector } from 'react-redux'
import { UserService } from 'app/services'
import Tooltip from 'app/components/Tooltip'

export function AsideMenu({ disableScroll }) {
    const { user } = useSelector(state => state.auth, shallowEqual)

    if (!user) return null

    const isOnlyAdmin = () => {
        return user?.hasRole(UserService.roles().ADMINISTRATOR) && user.roles_list?.length == 1;
    }

    return (
        <>
            {/* begin::Menu Container */}
            <div id="kt_aside_menu" data-menu-vertical="1" className={`aside-menu my-4`} data-menu-dropdown-timeout="500" data-menu-scroll="1">
                {user.hasRole(UserService.roles().SUPERADMIN) && <AsideMenuListSuperAdministrator />}
                {!user.hasRole(UserService.roles().SUPERADMIN) && !isOnlyAdmin() && <AsideMenuList />}
                {user.hasRole(UserService.roles().ADMINISTRATOR) && <AsideMenuListAdministrator />}

                <CreateTicketButton
                    onClick={() => {
                        window.location.href = `/tickets/create`;
                    }}
                />
            </div>
            {/* end::Menu Container */}
        </>
    )
}

const CreateTicketButton = ({ onClick }) => {
    return (
        <Tooltip title={
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p style={{ marginTop: 5, marginBottom: 5, color: '#A637A6', textAlign: 'center', fontWeight: 800, fontSize: 16 }}>¿Algún problema?</p>
                <p style={{ marginTop: 0, textAlign: 'center', fontWeight: 400, fontSize: 14 }}>Crea una incidencia para nuestro equipo técnico.</p>
            </div>
        }>
            <div
                onClick={onClick}
                style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    bottom: 20,
                    right: 20,
                    background: '#A041A0',
                    borderRadius: '50%',
                    overflow: 'hidden',
                    padding: 20,
                    boxShadow: 'rgb(38, 57, 77) 0px 20px 30px -10px',
                }}>
                <img src="https://cdn-icons-png.flaticon.com/512/3649/3649795.png" style={{ width: 30, height: 30, objectFit: 'contain' }} />
            </div>
        </Tooltip>
    );
}
