import { ApiService, encodeQueryData, getAccessToken, getBaseUrl, NormalizeResponse, SERVICES } from '../../BaseService';

export const CrossService = {
    get: (filters: any = {}) => {
        return NormalizeResponse(ApiService(SERVICES.WORK_PACKAGE_BUSINESS).get(`cross?${encodeQueryData(filters)}`));
    },
    getExportURL: (filters: any = {}) => {
        return `${getBaseUrl(SERVICES.WORK_PACKAGE_BUSINESS)}cross?${encodeQueryData(filters)}&export=xlsx&access_token=${getAccessToken(SERVICES.WORK_PACKAGE_BUSINESS)}`;
    }
};
