import { ApiService, NormalizeResponse, SERVICES } from './BaseService';

export const UserService = {
    upsert: (user: any) => {
        return ApiService(SERVICES.CONNECTOR_BACKEND).post('users/upsert', user);
    },
    getMe: () => {
        return ApiService(SERVICES.CONNECTOR_BACKEND).get('users/me');
    },
    getById: (id: number) => {
        return NormalizeResponse(ApiService(SERVICES.CONNECTOR_BACKEND).get(`users/${id}`));
    },
    deleteById: (id: number) => {
        return NormalizeResponse(ApiService(SERVICES.CONNECTOR_BACKEND).delete(`users/${id}`));
    },
    getUsers: () => {
        return ApiService(SERVICES.CONNECTOR_BACKEND).get('users/list');
    },
    updateMe: (data: any) => {
        return ApiService(SERVICES.CONNECTOR_BACKEND).put('users/me', data);
    },
    getWorkPackages: () => {
        return NormalizeResponse(ApiService(SERVICES.CONNECTOR_BACKEND).get(`work_packages`));
    },
    hasPermissionsToWorkPackage: (workPackageId: number) => {
        return NormalizeResponse(ApiService(SERVICES.CONNECTOR_BACKEND).get(`work_packages/${workPackageId}/permissions`));
    },
    togglePermissionsWorkPackage: (workPackageId: number, userId: number, value: boolean) => {
        return NormalizeResponse(ApiService(SERVICES.CONNECTOR_BACKEND).post(`work_packages/${workPackageId}/users/${userId}/permissions/${value}`));
    },

    // Available roles
    roles: () => {
        return {
            SUPERADMIN: 'superadmin',
            APP_PRECIOS: 'app-acceso-stock-price',
            ADMINISTRATOR: 'administrator',
            SALES: 'sales',
            SUPPLIER: 'supplier',
            DISTRIBUTOR: 'distributor',
            FARMER: 'farmer',
            MARKETING: 'marketing',
            RTV: 'vendedor'
        }
    }
}