/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useEffect } from 'react'
import { Nav, Tab, Dropdown, OverlayTrigger } from 'react-bootstrap'
import PerfectScrollbar from 'react-perfect-scrollbar'
import SVG from 'react-inlinesvg'
import objectPath from 'object-path'
import { useHtmlClassService } from '../../../_core/MetronicLayout'
import { toAbsoluteUrl } from '../../../../_helpers'
import { DropdownTopbarItemToggler } from '../../../../_partials/dropdowns'
import { NotificationService } from 'app/services'
import moment from 'moment'
import _ from 'lodash';

// Dialog
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip } from '@material-ui/core';

const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false,
}

export function UserNotificationsDropdown() {
    const [key, setKey] = useState('Notifications')
    const [notification, setNotification] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const bgImage = toAbsoluteUrl('/media/misc/bg-1.jpg')

    useEffect(() => {
        NotificationService.getMyNotifications({
            seen: false
        }).then((data) => {
            if(data.data.success) {
                setNotifications(data.data.notifications);
            }
        })
    }, []);

    const seenNotifications = () => {
        for(var idx in notifications) {
            NotificationService.markAsSeen(notifications[idx].id);
            notifications[idx].seen = true;
        }
        
        setTimeout(() => {
            setNotifications([...notifications]);
        }, 4000);
    }

    return (
        <>
            <Dropdown drop="down" alignRight>
                <Dropdown.Toggle
                    as={DropdownTopbarItemToggler}
                    id="kt_quick_notifications_toggle">
                    <OverlayTrigger
                        placement="bottom">
                        <Tooltip title={'Notificaciones'}>
                            <div
                                className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
                                id="kt_quick_notifications_toggle"
                                onClick={seenNotifications}>
                                <span className="svg-icon svg-icon-xl svg-icon-primary">
                                    <SVG
                                        src={toAbsoluteUrl(
                                            '/media/svg/top_bar/mail.svg'
                                        )}
                                    />

                                    { notifications.length > 0 && _.some(notifications, i => i.seen == false) &&
                                        <div style={{ width: 20, height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10, backgroundColor: '#3699FF', borderRadius: 15, position: 'absolute', top: 0, right: 0, fontSize: '0.7em', fontWeight: '800', color: 'white' }}>
                                            { notifications.filter((i) => i.seen == false).length }
                                        </div>
                                    }
                                </span>

                                { notifications.length > 0 && _.some(notifications, i => i.seen == false) && [
                                    <span className="pulse-ring"></span>,
                                    <span className="pulse-ring" />  
                                ]}
                            </div>
                        </Tooltip>
                    </OverlayTrigger>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
                    <form>
                        {/** Head */}
                        <div
                            className="d-flex flex-column pt-0 bgi-size-cover bgi-no-repeat rounded-top"
                            style={{ backgroundImage: `url(${bgImage})` }}>
                            <Tab.Container defaultActiveKey={key}>
                                <Nav
                                    as="ul"
                                    className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
                                    onSelect={_key => setKey(_key)}>
                                    <Nav.Item as="li">
                                        <Nav.Link
                                            eventKey="Notifications"
                                            className={`nav-link show ${
                                                key === 'Notifications'
                                                    ? 'active'
                                                    : ''
                                            }`}>
                                            Notificaciones
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>

                                <Tab.Content className="tab-content">
                                    <Tab.Pane
                                        eventKey="Notifications"
                                        id="topbar_notifications_events">

                                        <PerfectScrollbar
                                            options={
                                                perfectScrollbarOptions
                                            }
                                            className="navi navi-hover scroll my-4"
                                            style={{
                                                maxHeight: '300px',
                                                position: 'relative',
                                            }}>

                                            { notifications && notifications.length > 0 && notifications.map((notification) => {
                                                return (
                                                    <a
                                                        key={notification.id}
                                                        href="#"
                                                        className="navi-item"
                                                        onClick={() => {
                                                            setNotification(notification);
                                                        }}>
                                                        <div className="navi-link">
                                                            <div className="navi-icon mr-2">
                                                                <i className="flaticon2-line-chart text-success"></i>
                                                            </div>
                                                            <div className="navi-text">
                                                                <div className="font-weight-bold">
                                                                    { notification.title }
                                                                </div>
                                                                <div className="text-muted">
                                                                    { notification.text_sanitized.substring(0, 25) + '...' }
                                                                </div>
                                                                <div className="text-muted">
                                                                    { moment(notification.created_at).fromNow() }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                )
                                            })}

                                        { notifications && notifications.length == 0 &&
                                            <p className="pl-5 pt-2">Aún no hay nuevas notificaciones</p>
                                        }
                                        </PerfectScrollbar>
                                    </Tab.Pane>
                                    <Tab.Pane
                                        eventKey="Logs"
                                        id="topbar_notifications_logs">
                                        <div className="d-flex flex-center text-center text-muted min-h-200px">
                                            Todo leído!
                                            <br />
                                            No hay nuevas notificaciones
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </form>
                </Dropdown.Menu>
            </Dropdown>

            { notification != null &&
                <Dialog
                    open={notification && notification.id}
                    onClose={( )=> {
                        setNotification(null);
                    }}>
                    <DialogTitle id="alert-dialog-title">
                        { notification.title }
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <pre style={{ fontWeight: 200, fontSize: '0.9em' }}>
                            { notification.text_sanitized }
                        </pre>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            setNotification(null);
                        }} color="primary">
                            Cerrar
                        </Button>
                    </DialogActions>
                </Dialog>
            }
        </>
    )
}
