import React from 'react'
import { Link } from 'react-router-dom'

export function Brand() {
    return (
        <>
            {/* begin::Brand */}
            <div
                className={`brand flex-column-auto`}
                id="kt_brand">
                {/* begin::Logo */}
                <Link to="" className="brand-logo">
                    {/* <img alt="logo" src={'/media/logos/logo-light.png'} /> */}
                    <img alt="logo" src={'/media/logos/logo.png'} />
                    <img style={{ marginLeft: 10 }} alt="logo" src={'/media/logos/logo_text.png'} />
                </Link>
                {/* end::Logo */}

                {
                    <>
                        {/* begin::Toggle */}
                        <button
                            className="brand-toggle btn btn-sm px-0"
                            id="kt_aside_toggle"
                            style={{ position: 'absolute', right: 4 }}>
                            <div className={`icon-bg`} />
                        </button>
                        {/* end::Toolbar */}
                    </>
                }
            </div>
            {/* end::Brand */}
        </>
    )
}
