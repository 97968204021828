import React, { useEffect, useState } from 'react'

import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import _ from 'lodash'

// @ts-ignore
import OverlayLoading from 'app/components/OverlayLoading'
import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client'
import { shallowEqual, useSelector } from 'react-redux'
import { toast } from 'react-hot-toast'
import Tooltip from 'app/components/Tooltip'
import { useStores } from 'app/mobx/useStore'
import { getBaseUrl, SERVICES } from 'app/services/BaseService'
import { useAnalytics } from 'app/hooks'
import { UserService } from 'app/services'

export function SalesDashboardPage() {
    const stores = useStores()
    const analytics = useAnalytics()
    const { user } = useSelector((state: any) => state.auth, shallowEqual)
    const [loading, setLoading] = useState<boolean>(false)
    const [embedData, setEmbedData] = useState<any>(null)
    const [reportId, setReportId] = useState<any>(`be8ce7dd-fee5-4214-8981-8fa09a83ecbd`)
    const [datasetId, setDatasetId] = useState<any>(`6195a8fa-346a-4ff8-9d28-fbc334c367ab`)
    const [workspaceId, setWorkspaceId] = useState<any>(`4dd6453c-8861-4074-af90-1b2febc4a935`)

    useEffect(() => {
        setLoading(true)

        // Logic to get the report ID based on the current user / role id
        // const roleIds = user.powerbi_report.role_id.split(',');
        let identities: any[] = []

        let REPORT_ID: string = reportId
        let DATASET_ID: string = datasetId
        let WORKSPACE_ID: string = workspaceId

        // For sales, supply and finance we need to set role = 'Admin'
        if (user?.hasRole('administrator') || user?.hasRole('sales') || user?.hasRole('finance') || user?.hasRole('supply') || user?.hasRole(UserService.roles().APP_PRECIOS)) {
            identities = [
                {
                    username: user.email,
                    roles: ['Admin'],
                },
            ]
        } else if (user && user.sources && user.sources.length > 0) {
            if (user.sources.length == 1) {
                // Exceptions for the powerbi reports
                // where we need to show multiple zones instead of just 1
                // in that case, we created separated reports on power bi
                const exceptions: any[] = [
                    { fromEmail: 'marion.garcia@upl-ltd.com', roleId: 'Zonas Marion' },
                    { fromEmail: 'gustavo.barbero@upl-ltd.com', roleId: 'Zonas Gustavo' },
                    { fromEmail: 'walter.palandri@upl-ltd.com', roleId: 'Zonas Palandri' },
                ]

                // Show the zone for the respective RTV
                const source: any = _.find(stores.source.sources, (i: any) => i.id == user.wp_data.source_id)
                if (source && source.groups) {
                    // Check for exceptions
                    const exception: any = _.find(exceptions, (i: any) => i.fromEmail === user.email)
                    if (exception) {
                        identities = [
                            {
                                username: exception.fromEmail,
                                roles: [exception.roleId],
                            },
                        ]
                    } else {
                        identities = _.map(source.groups, (group: any) => {
                            return {
                                username: group.source_group_desc,
                                roles: ['Zonas'],
                            }
                        })
                    }
                }
            } else {
                // Regional
                const source: any = _.find(stores.source.sources, (i: any) => i.id == user.wp_data.source_id)
                if (source && source.groups) {
                    identities = [
                        {
                            username: _.uniq(_.map(source.groups, (i: any) => i.source_group_parent_code)).join('_'),
                            roles: _.uniq(_.map(source.groups, (i: any) => `Region_${i.source_group_parent_code}`)),
                        },
                    ]
                }
            }
        }

        let baseUrl = getBaseUrl(SERVICES.CONNECTOR_BACKEND)
        if (baseUrl.endsWith('/')) baseUrl = baseUrl.slice(0, -1)
        let url = `${baseUrl}/azure/reports/getEmbedToken?reportId=${REPORT_ID}&datasetId=${DATASET_ID}&workspaceId=${WORKSPACE_ID}`

        if (identities && identities.length > 0) url += `&identities=${JSON.stringify(identities)}`

        // Set variables
        setReportId(REPORT_ID)
        setDatasetId(DATASET_ID)
        setWorkspaceId(WORKSPACE_ID)

        fetch(url)
            .then(res => res.json())
            .then(result => {
                if (result != null) {
                    analytics.trackEvent('Navigation', {
                        type: window.location.pathname,
                    })

                    setEmbedData({
                        accessToken: result.token,
                        embedUrl: result.embedUrl,
                        reportId: result.reportId,
                    })
                }
            })
            .catch((err: any) => {
                toast.error('Error al cargar el reporte. Inténtelo nuevamente en unos minutos.')
            })
            .finally(() => {
                setLoading(false)
            })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div style={{ position: 'relative', padding: 10 }}>
            <h1 style={{ marginBottom: 20 }}>
                Dashboard de ventas
                <Tooltip
                    title={
                        <>
                            <p className={'m-2 text-center'}>
                                ID de reporte: <b>{reportId}</b>
                            </p>
                            <p className={'m-2 text-center'}>
                                ID de dataset: <b>{datasetId}</b>
                            </p>
                            <p className={'m-0 text-center'}>
                                ID de workspace: <b>{workspaceId}</b>
                            </p>
                        </>
                    }>
                    <HelpOutlineIcon style={{ marginLeft: 10, fontSize: '0.9em', color: 'rgba(0,0,0,.5)' }} />
                </Tooltip>
            </h1>

            {embedData != null && (
                <PowerBIEmbed
                    embedConfig={{
                        type: 'report', // Supported types: report, dashboard, tile, visual and qna
                        id: embedData.reportId,
                        embedUrl: embedData.embedUrl,
                        accessToken: embedData.accessToken,
                        tokenType: models.TokenType.Embed,
                        settings: {
                            panes: {
                                filters: {
                                    expanded: false,
                                    visible: false,
                                },
                            },
                            background: models.BackgroundType.Transparent,
                        },
                    }}
                    eventHandlers={
                        new Map([
                            ['loaded', (event: any, embeddedReport: any) => {}],
                            [
                                'rendered',
                                (event: any, embeddedReport: any) => {
                                    setLoading(false)
                                },
                            ],
                            [
                                'error',
                                (event: any) => {
                                    /* console.log(event.detail); */
                                },
                            ],
                            [
                                'visualClicked',
                                (event: any) => {
                                    console.log('Visual clicked', event)
                                },
                            ],
                            [
                                'dataSelected',
                                (event: any, embeddedReport: any) => {
                                    console.log('Data selected', event)
                                },
                            ],
                            [
                                'selectionChanged',
                                (event: any) => {
                                    console.log('Selection changed', event)
                                },
                            ],
                        ])
                    }
                    cssClassName={'report-style-class'}
                    getEmbeddedComponent={embeddedReport => {}}
                />
            )}

            <OverlayLoading visible={loading} />
        </div>
    )
}
