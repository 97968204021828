/* eslint-disable no-useless-escape */

export const getDatesMonthlyBetweenDates = (startDate: any, endDate: any) => {
    var timeValues = [];

    while (endDate > startDate || startDate.format('M') === endDate.format('M')) {
        timeValues.push(startDate.format('YYYY-MM'));
        startDate.add(1,'month');
    }

    return timeValues;
}
