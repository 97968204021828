import autoSave from '../autoStore';
import { makeAutoObservable } from "mobx"
import { KTUtil } from '_metronic/_assets/js/components/util';

class UIStore {
    mobile: boolean = false

    constructor() {
        makeAutoObservable(this)
        autoSave(this);

        this.mobile = KTUtil.isMobileDevice();
    }

    isMobile(): boolean {
        return this.mobile;
    }
}

const ui = new UIStore();

export {
    ui
};