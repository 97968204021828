import React from "react";
import { Brand } from "../brand/Brand";
import { AsideMenu } from "./aside-menu/AsideMenu";

export function Aside() {
    return (
        <>
            <div id="kt_aside"
                className={`aside aside-left aside-fixed d-flex flex-column flex-row-auto`}
                style={{ zIndex: 1 }}>
                <Brand />

                <div id="kt_aside_menu_wrapper" className="aside-menu-wrapper flex-column-fluid">
                    <AsideMenu disableScroll={false} />
                </div>
            </div>
        </>
    );
}
