import axios from "axios";

import {
    AuthService,
    UserService
} from 'app/services';

export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export function login(username, password) {
    return AuthService.login({
        username,
        password
    });
}

export function register(email, fullname, username, password) {
    return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
    return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
    // Authorization head should be fulfilled in interceptor.
    return UserService.getMe();
}
