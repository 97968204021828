import { useEffect } from 'react'

export interface IAppConstants {
    appName: string
}

export function useAppConstants() {
    useEffect(() => {
        
    });

    return {
        appName: 'Bloom AI'
    } as IAppConstants;
}