import React from 'react';
import { Tooltip as MaterialTooltip } from '@material-ui/core';
import { KTUtil } from '_metronic/_assets/js/components/util';
import _ from 'lodash';

const DEFAULT_PROPS: any = {
    arrow: true,
    placement: 'top'
};

const Tooltip = (props: any) => {
    // Hide in mobile by default
    if(KTUtil.isMobileDevice())
        return null;

    const properties = Object.assign(_.clone(_.omit(props, ['children'])), DEFAULT_PROPS);

    if(!props.title) {
        return (
            <>{ props.children }</>
        )
    }

    return (
        <MaterialTooltip {...properties}>
            { props.children }
        </MaterialTooltip>
    )
}

export default Tooltip;